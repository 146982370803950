import type { ApiErrorResponse } from '@ping/api';

import style from './style.module.scss';

type IApiErrorResponseToastContentProps = Omit<ApiErrorResponse, 'errorCode'>;

const ApiErrorResponseToastContent = ({ errors, message }: IApiErrorResponseToastContentProps) => (
  <div className={style['api-error-response-toast-content']}>
    <div>{message}</div>
    <ul className={style['api-error-response-toast-content__errors']}>
      {errors?.map(error => (
        <li className={style['api-error-response-toast-content__error']} key={error.key + error.code}>
          {error.message}
        </li>
      ))}
    </ul>
  </div>
);

export default ApiErrorResponseToastContent;
