/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Trader API
 * Build date:  <br />
Build branch: dev <br />
Build commit: d7421a4e <br />
Environment: Staging

 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from 'react-query';
import { customInstance, ErrorType } from './custom-instance';
export type GetRateHistoryCharting200Three = { [key: string]: number[] };

export type GetRateHistoryCharting200Two = { [key: string]: number[] };

export type GetRateHistoryCharting200One = { [key: string]: number[] };

export type GetHistoryChartingParams = { startDate?: string; endDate?: string; type?: string; count?: number };

export type GetAllUserParams = {
  Search?: string;
  Status?: ActivityStatus;
  FromTotalBalance?: number;
  ToTotalBalance?: number;
  FromLastDepositDate?: string;
  ToLastDepositDate?: string;
  FromLastDepositAmount?: number;
  ToLastDepositAmount?: number;
  FromTradeVolume?: number;
  ToTradeVolume?: number;
  AscOrder?: string;
  DescOrder?: string;
  Page?: number;
  PerPage?: number;
};

export type GetTransfersAsCsvParams = {
  From: string;
  To?: string;
  User?: string;
  Account?: string;
  Status?: TransferStatus;
  Asset?: string;
  TransferId?: string;
  Type?: TransferType;
  Cursor?: string;
  Sort?: string[];
};

export type GetTransfersParams = {
  From: string;
  To?: string;
  User?: string;
  Account?: string;
  Status?: TransferStatus;
  Asset?: string;
  TransferId?: string;
  Type?: TransferType;
  Cursor?: string;
  Sort?: string[];
};

export type TradesGetOrdersParams = {
  From: string;
  To?: string;
  User?: string;
  Account?: number;
  OrderId?: string;
  TradeId?: number;
  Market?: string;
  Cursor?: string;
  Sort?: string[];
};

export type GetProfitParams = { CurrencyId: string; From?: string; To?: string };

export type OrdersGetOrdersAsCsvParams = {
  From: string;
  To?: string;
  CorePassId?: string;
  User?: string;
  Account?: string;
  OrderId?: string;
  Status?: OrderStatus;
  Market?: string;
  Type?: OrderType[];
  Sort?: string[];
  Cursor?: string;
};

export type OrdersGetOrdersParams = {
  From: string;
  To?: string;
  CorePassId?: string;
  User?: string;
  Account?: string;
  OrderId?: string;
  Status?: OrderStatus;
  Market?: string;
  Type?: OrderType[];
  Sort?: string[];
  Cursor?: string;
};

export type GetTransferBalanceHotWalletParams = { paymentSystem?: string; assetId?: string; cursor?: string };

export type GetTransferHistoryWalletParams = {
  AssetId?: string;
  Type?: TransferType;
  StartDate?: string;
  EndDate?: string;
  AscOrder?: string[];
  DescOrder?: string[];
  Page?: number;
  PerPage?: number;
};

export type SwapTradeParams = { FromAssetId?: string; ToAssetId?: string; Amount?: number };

export type GetTradeHistoryParams = {
  Market?: string;
  Side?: OrderSide;
  StartDate?: string;
  EndDate?: string;
  AscOrder?: string[];
  DescOrder?: string[];
  Page?: number;
  PerPage?: number;
};

export type OrderHistoryTradeParams = {
  Market?: string;
  Side?: OrderSide;
  StartDate?: string;
  EndDate?: string;
  AscOrder?: string[];
  DescOrder?: string[];
  IsHideCanceled?: boolean;
  Page?: number;
  PerPage?: number;
};

export type GetRewardsReferralParams = { Page?: number; PerPage?: number };

export type GetOrCreateProfileParams = { referralCode?: string };

export interface QuoteInfo {
  instrument?: string | null;
  start?: string;
  end?: string;
  low?: number;
  high?: number;
  volume?: number;
  open?: number;
  close?: number;
  GoinGeckoPrice?: number;
}

export interface OrderBookLevelInfo {
  amount?: number;
  price?: number;
}

export interface OrderBookInfo {
  instrument?: string | null;
  bids?: OrderBookLevelInfo[] | null;
  asks?: OrderBookLevelInfo[] | null;
  version?: number;
  askTotalAmount?: number;
  bidTotalAmount?: number;
  snapshot?: boolean;
}

export interface ChartHistoryResponse {
  success?: boolean;
  errorMessage?: string | null;
  instrument?: string | null;
  data?: QuoteInfo[] | null;
  startDateTime?: string | null;
  endDateTime?: string | null;
}

export interface UserResponse {
  id?: string | null;
  isActive?: boolean;
  email?: string | null;
  isEmailConfirmed?: boolean;
  nickname?: string | null;
  isPhoneConfirmed?: boolean;
  registrationDate?: string | null;
  isAmbassador?: boolean;
  status?: ActivityStatus;
  roles?: string[] | null;
  accounts?: BasicAccountInfo[] | null;
  verificationLevel?: number;
  tier?: UserTierType;
  isTierExpired?: boolean;
  tierExpiresAt?: string | null;
  lastSignInDate?: string | null;
  createdAt?: string | null;
  corePassId?: string | null;
}

export interface UserResponseResponse {
  data?: UserResponse;
}

export type UserItemResponseFilteredResponseFilters = unknown | null;

export interface UserItemResponse {
  id?: string | null;
  corePassId?: string | null;
  userName?: string | null;
  email?: string | null;
  totalBalance?: number;
  availableBalance?: number;
  totalOnHoldBalance?: number;
  lastDepositAmount?: number | null;
  lastDepositDate?: string | null;
  tradeVolume?: number | null;
  createdAt?: string | null;
  status?: ActivityStatus;
  kycStatus?: KycStatus;
  kycError?: string | null;
}

export interface UserItemResponseFilteredResponse {
  filters?: UserItemResponseFilteredResponseFilters;
  paging?: Paging;
  data?: UserItemResponse[] | null;
}

export interface UpdateUserRequest {
  isAmbassador?: boolean;
  status?: ActivityStatus;
  tradeLimit?: number | null;
}

export interface UpdateMarketRequest {
  baseFee?: number | null;
  quoteFee?: number | null;
  makerFee?: number | null;
  takerFee?: number | null;
  hidden?: boolean | null;
  priceScale?: number | null;
  amountScale?: number | null;
  minAmount?: number | null;
  status?: MarketStatus;
}

export interface TransferRecord {
  id?: string;
  accountId?: number;
  assetId?: string | null;
  paymentSystemId?: string | null;
  targetAddress?: string | null;
  version?: number;
  type?: TransferType;
  amount?: number;
  comment?: string | null;
  createdAt?: string;
  price?: number | null;
  updatedAt?: string;
  status?: TransferStatus;
  fee?: number;
  paymentSystem?: string | null;
  data?: string | null;
  errorMessage?: string | null;
}

/**
 * Type of the transaction
 */
export type TransactionType = typeof TransactionType[keyof typeof TransactionType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionType = {
  Deposit: 'Deposit',
  Withdrawal: 'Withdrawal',
} as const;

/**
 * List of transaction statuses.
 */
export type TransactionStatus = typeof TransactionStatus[keyof typeof TransactionStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionStatus = {
  AwaitingConfirmation: 'AwaitingConfirmation',
  Completed: 'Completed',
  Failed: 'Failed',
} as const;

export interface TransactionEventData {
  /** External transfer id, can be generated by looking at information in payment system alone. */
  paymentSystemId?: string | null;
  type?: TransactionType;
  /** Identifier used to connect wallet with account. */
  ownerId?: number;
  /** External transfer id used to identify transfer in external systems. */
  transferId?: string;
  status?: TransactionStatus;
  /** Asset being transferred. */
  assetId?: string | null;
  /** Information identifying transfer sender. */
  from?: string | null;
  /** Information identifying transfer recipient. */
  to?: string | null;
  /** Payment system being used for a transfer. */
  paymentSystem?: string | null;
  /** Amount being transferred. */
  amount?: number;
  /** Minimum number of confirmations required to transition transfer to Qodex.Wallet.TransactionStatus.Completed state. */
  minConfirmations?: number;
  /** Get number of confirmations from payment system. */
  confirmations?: number;
  /** Blockchain transaction id. */
  txId?: string | null;
  /** Error details from payment system API. */
  errorDetails?: string | null;
}

/**
 * Role of an order in a trade
 */
export type TradeRole = typeof TradeRole[keyof typeof TradeRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TradeRole = {
  Aggressor: 'Aggressor',
  Resting: 'Resting',
} as const;

/**
 * Trade information returned in the BackOffice API
 */
export interface TradeData {
  tradeId?: string | null;
  accountId?: string | null;
  orderId?: string | null;
  accountVersion?: number;
  market?: string | null;
  tradeTime?: string;
  side?: OrderSide;
  remainingAmount?: string | null;
  tradePrice?: string | null;
  tradeAmount?: string | null;
  tradeQuoteAmount?: string | null;
  tradeRole?: string | null;
  commission?: string | null;
  commissionCurrency?: string | null;
  commissionType?: string | null;
  /** Name of user those account is updated with this transfer. */
  userName?: string | null;
  /** Email of a user those account is updated with this transfer. */
  email?: string | null;
}

/**
 * Response returned by the Trades query API
 */
export interface TradesResponse {
  paging?: PagingData;
  sort?: string[] | null;
  data?: TradeData[] | null;
}

export type TagCardinality = typeof TagCardinality[keyof typeof TagCardinality];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TagCardinality = {
  Single: 'Single',
  Multiple: 'Multiple',
} as const;

export type RoleType = typeof RoleType[keyof typeof RoleType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RoleType = {
  Admin: 'Admin',
} as const;

export interface RoleRequest {
  userId?: string;
  roles?: RoleType[] | null;
}

export interface ProfitResponse {
  profit?: number;
  income?: number;
  expense?: number;
  currencyId?: string | null;
}

export interface PagingData {
  next?: string | null;
  prev?: string | null;
}

/**
 * Order information returned in the Backoffice API
 */
export interface OrderData {
  /** Order id */
  orderId?: string | null;
  /** Account where this order has been placed at */
  accountId?: string | null;
  /** Account owner id */
  userId?: string | null;
  /** Symbol of the tradable instrument */
  market?: string | null;
  /** Account version that corresponds to this order */
  accountVersion?: number;
  /** Order creation time */
  createdAt?: string;
  /** Order last transaction time */
  updatedAt?: string;
  orderStatus?: OrderStatus;
  cancelReason?: CancelReason;
  orderType?: OrderType;
  side?: OrderSide;
  /** Requested amount of the order */
  requestedAmount?: string | null;
  /** Remaining amount of the order */
  remainingAmount?: string | null;
  /** Total filled amount of the order */
  filledAmount?: string | null;
  /** Requested limit price */
  requestedLimitPrice?: string | null;
  /** Requested stop price */
  requestedStopPrice?: string | null;
  /** Trigger price */
  triggerPrice?: string | null;
  /** Effective limit price */
  effectiveLimitPrice?: string | null;
  /** Average fill price */
  averageFillPrice?: string | null;
  /** Time in Force */
  timeInForce?: string | null;
  /** Order expiration time (for non-GTC orders) */
  expirationTime?: string | null;
  /** Reject code */
  rejectReason?: string | null;
  /** Human-readable reject reason */
  rejectDetails?: string | null;
  /** Total commission amount paid for this order */
  totalCommission?: string | null;
  /** Order commission currency */
  commissionCurrency?: string | null;
  /** Name of user those account is updated with this transfer. */
  userName?: string | null;
  /** Email of a user those account is updated with this transfer. */
  email?: string | null;
  corePassId?: string | null;
}

/**
 * Response returned by the Orders query API
 */
export interface OrdersResponse {
  paging?: PagingData;
  sort?: string[] | null;
  data?: OrderData[] | null;
}

export type MarketStatus = typeof MarketStatus[keyof typeof MarketStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MarketStatus = {
  Open: 'Open',
  Halted: 'Halted',
  Paused: 'Paused',
} as const;

export interface MarketResponse {
  id?: string | null;
  baseAsset?: string | null;
  quoteAsset?: string | null;
  maxPrice?: number;
  minPrice?: number;
  minAmount?: number;
  hidden?: boolean;
  baseFee?: number;
  quoteFee?: number;
  makerFee?: number;
  makerFeeLimit?: number;
  takerFee?: number;
  takerFeeLimit?: number;
  priceScale?: number;
  amountScale?: number;
  status?: MarketStatus;
}

export interface ManualTransferRequest {
  userId: string;
  asset: string;
  type: TransferType;
  amount: number;
  status: TransferStatus;
  comment?: string | null;
  errorDetails?: string | null;
  code?: string | null;
}

export type KycStatus = typeof KycStatus[keyof typeof KycStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const KycStatus = {
  Kyc: 'Kyc',
  InProgress: 'InProgress',
  Failed: 'Failed',
} as const;

/**
 * Wallet balance information.
 */
export interface HotWalletInfoResponse {
  /** Cold wallet for admin to withdraw money */
  coldWalletAddress?: string | null;
  /** Error thrown while fetching asset balance */
  error?: string | null;
  /** Total amount of funds, can be outdated. */
  amount?: number | null;
  /** Blockchain address for most crypto currencies or bank deposit details or payment system link for fiat assets. */
  address?: string | null;
  /** Payment system which processes this wallet transactions. */
  paymentSystem?: string | null;
  /** Asset identifier. */
  assetId?: string | null;
}

/**
 * Execution type
 */
export type ExecType = typeof ExecType[keyof typeof ExecType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExecType = {
  OrderStatusUpdate: 'OrderStatusUpdate',
  Trade: 'Trade',
} as const;

/**
 * Request to transfer funds out of hot wallet to cold wallet
 */
export interface ColdWalletTransferRequest {
  /** Asset id to transfer. */
  assetId: string;
  /** Amount to transfer. */
  amount: number;
  /** Optional payment system to use. */
  paymentSystem?: string | null;
}

/**
 * Request payload to cancel order. Order id provided via url.
 */
export interface CancelOrderRequestPayload {
  status?: OrderStatus;
}

/**
 * Brief information about an order execution
 */
export interface BriefExecution {
  /** Account version that corresponds to this execution */
  accountVersion?: number;
  /** Execution creation time */
  createdAt?: string;
  execType?: ExecType;
  /** Unique trade id, null for non-trades */
  tradeId?: string | null;
  orderStatus?: OrderStatus;
  cancelReason?: CancelReason;
  /** Remaining amount of the order after this execution */
  remainingAmount?: string | null;
  /** Total filled amount of the order after this execution */
  filledAmount?: string | null;
  /** Amount of the trade in quote currency */
  filledQuoteAmount?: string | null;
  /** Amount of the trade in base currency */
  filledBaseAmount?: string | null;
  /** Trade price (for trades), null otherwise */
  tradePrice?: string | null;
  /** Amount of the trade, null for non-trades */
  tradeAmount?: string | null;
  tradeRole?: TradeRole;
  /** Trigger price (for triggered stops) */
  triggerPrice?: string | null;
  /** Reject code */
  rejectReason?: string | null;
  /** Human-readable reject reason */
  rejectDetails?: string | null;
  /** Commission paid for this execution */
  commission?: string | null;
  /** Commission currency */
  commissionCurrency?: string | null;
  /** Commission type */
  commissionType?: string | null;
}

/**
 * Detailed information about an order.
 */
export interface OrderDetails {
  order?: OrderData;
  /** Brief information about all order executions (order from the oldest to the most newest ones). */
  executions?: BriefExecution[] | null;
}

export interface BotResponse {
  /** Identifier of bot. */
  id?: string | null;
  /** Type of bot. */
  type?: string | null;
  /** Human-readable name. */
  name?: string | null;
  /** Is bot enabled? */
  enabled?: boolean;
}

export interface BotResponsePagedResponse {
  paging?: Paging;
  data?: BotResponse[] | null;
}

export interface BotRequest {
  enabled?: boolean;
}

export interface AssetRequest {
  can_deposit?: boolean | null;
  can_withdraw?: boolean | null;
  withdraw_fee?: number | null;
  scale?: number | null;
  min_withdraw?: number | null;
}

export interface ApiErrorData {
  key?: string | null;
  code?: string | null;
  message?: string | null;
  [key: string]: unknown;
}

export interface ApiErrorResponse {
  errorCode?: number;
  message?: string | null;
  errors?: ApiErrorData[] | null;
}

export type AdminUserTierRequestType = typeof AdminUserTierRequestType[keyof typeof AdminUserTierRequestType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdminUserTierRequestType = {
  Basic: 'Basic',
  PremiumForced: 'PremiumForced',
} as const;

export interface AdminUserTierRequest {
  userId?: string;
  tier?: AdminUserTierRequestType;
}

export interface AdminTransferData {
  /** Identifies account those balance being updated with this transfer. */
  accountId?: string | null;
  /** Name of user those account is updated with this transfer. */
  userName?: string | null;
  /** User id */
  userId?: string | null;
  /** Email of a user those account is updated with this transfer. */
  email?: string | null;
  corePassId?: string | null;
  id?: string;
  asset?: string | null;
  paymentSystem?: string | null;
  transferId?: string | null;
  type?: TransferType;
  status?: TransferStatus;
  amount?: number;
  fee?: number;
  errorDetails?: string | null;
  createdAt?: string;
  updatedAt?: string;
  txId?: string | null;
  blockchainLink?: string | null;
  errorCode?: string | null;
  confirmations?: number;
  confirmationsRequired?: number;
  targetAddress?: string | null;
}

export interface TransfersResponse {
  paging?: PagingData;
  sort?: string[] | null;
  data?: AdminTransferData[] | null;
}

export interface AdminManualTransferResponse {
  twoFactorRequired?: boolean;
  provider?: string | null;
  data?: AdminTransferData;
}

export type ActivityStatus = typeof ActivityStatus[keyof typeof ActivityStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ActivityStatus = {
  Active: 'Active',
  Frozen: 'Frozen',
  Terminated: 'Terminated',
} as const;

export interface AccountTagInfo {
  id?: number;
  name?: string | null;
  type?: string | null;
  cardinality?: TagCardinality;
  values?: string[] | null;
  editable?: boolean;
}

export interface AccountTagData {
  tagId?: number;
  recordId?: number;
  value?: string | null;
}

export interface WithdrawalRequest {
  assetId?: string | null;
  amount?: number;
  address?: string | null;
  comment?: string | null;
  code?: string | null;
}

export interface WithdrawalLimitResponse {
  dailyLimit?: number;
  currentLimit?: number;
}

export type UserTierType = typeof UserTierType[keyof typeof UserTierType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserTierType = {
  Basic: 'Basic',
  Premium: 'Premium',
  PremiumForced: 'PremiumForced',
} as const;

/**
 * Represents user settings and presets
 */
export interface UserSettingsResponse {
  favoriteMarkets?: string[] | null;
  presets?: string | null;
  currentCurrency?: string | null;
  currentMarketType?: string | null;
}

export interface UserSettingsResponseResponse {
  data?: UserSettingsResponse;
}

export interface TwoFactorAuthCallbackRequest {
  corepassid?: string | null;
  signature?: string | null;
}

export type TransferType = typeof TransferType[keyof typeof TransferType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferType = {
  Deposit: 'Deposit',
  Withdrawal: 'Withdrawal',
} as const;

export type TransferStatus = typeof TransferStatus[keyof typeof TransferStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransferStatus = {
  Working: 'Working',
  Pending: 'Pending',
  Completed: 'Completed',
  Failed: 'Failed',
} as const;

export type TransferHistoryItemFilteredResponseFilters = unknown | null;

export interface TransferHistoryItem {
  transferId?: string | null;
  assetId?: string | null;
  createdAt?: string;
  amount?: number;
  status?: TransferStatus;
  type?: TransferType;
  targetAddress?: string | null;
  paymentSystemId?: string | null;
}

export interface TransferHistoryItemFilteredResponse {
  filters?: TransferHistoryItemFilteredResponseFilters;
  paging?: Paging;
  data?: TransferHistoryItem[] | null;
}

export type TradeHistoryItemFilteredResponseFilters = unknown | null;

export interface TradeHistoryItem {
  tradeSeq?: number;
  tradeTime?: string;
  amount?: number;
  quoteAmount?: number;
  remaining?: number;
  executionPrice?: number;
  instrument?: string | null;
  side?: OrderSide;
  commission?: number | null;
  orderId?: string | null;
  transactionId?: number;
  status?: OrderStatus;
}

export interface TradeHistoryItemFilteredResponse {
  filters?: TradeHistoryItemFilteredResponseFilters;
  paging?: Paging;
  data?: TradeHistoryItem[] | null;
}

export interface TagInfo {
  id?: number;
  name?: string | null;
  values?: string[] | null;
}

export interface SwapResponse {
  rate?: number;
  currencyRate?: number;
  totalConversion?: number;
  userCurrency?: string | null;
}

export type SelfMatchPreventionStrategy = typeof SelfMatchPreventionStrategy[keyof typeof SelfMatchPreventionStrategy];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SelfMatchPreventionStrategy = {
  cancelAggressor: 'cancelAggressor',
  cancelResting: 'cancelResting',
  cancelBoth: 'cancelBoth',
} as const;

export type RewardStatus = typeof RewardStatus[keyof typeof RewardStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RewardStatus = {
  NoReward: 'NoReward',
  ShareReward: 'ShareReward',
  RewardOrderMade: 'RewardOrderMade',
  RewardSharingDone: 'RewardSharingDone',
} as const;

export type ReferralUserType = typeof ReferralUserType[keyof typeof ReferralUserType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReferralUserType = {
  Ambassador: 'Ambassador',
  ReferralAgent: 'ReferralAgent',
  Maker: 'Maker',
} as const;

export type ReferralRewardHistoryResponseFilteredResponseFilters = unknown | null;

export type ReferralRewardHistoryResponseRewards = { [key: string]: number } | null;

export interface ReferralRewardHistoryResponse {
  dateTime?: string;
  rewards?: ReferralRewardHistoryResponseRewards;
  type?: ReferralUserType;
  rewardStatus?: RewardStatus;
}

export interface ReferralRewardHistoryResponseFilteredResponse {
  filters?: ReferralRewardHistoryResponseFilteredResponseFilters;
  paging?: Paging;
  data?: ReferralRewardHistoryResponse[] | null;
}

export interface ReferralResponse {
  referralCode?: string | null;
  isAmbassador?: boolean;
  hasReferrer?: boolean;
  firstLevelRefereeCount?: number;
  secondLevelRefereeCount?: number;
}

export interface RefereeRewardResponse {
  corePassId?: string | null;
  refereesCount?: number | null;
  totalCommission?: number;
  convertedTotalCommission?: number;
  readonly commissionPercentage?: number;
}

export interface ReferralStatusResponse {
  refereesCount?: number;
  totalEarnedCommission?: number;
  convertedCommission?: number;
  recentEarnedCommission?: number;
  isAmbassador?: boolean;
  refereesCommissionList?: RefereeRewardResponse[] | null;
}

export interface ProfileSettingsUpdateRequest {
  type?: string | null;
  value?: string | null;
}

export interface ProfileResponse {
  id?: string | null;
  email?: string | null;
  roles?: string[] | null;
  accounts?: BasicAccountInfo[] | null;
  nickname?: string | null;
  verificationLevel?: number;
  verificationStartedAt?: string;
  verificationIsInProgress?: boolean;
  verificationError?: string | null;
  verificationFailedAt?: string | null;
  tier?: UserTierType;
  isTierExpired?: boolean;
  tierExpiresAt?: string | null;
  lastSignInDate?: string | null;
  createdAt?: string | null;
}

export interface PortfolioItemResponse {
  assetId?: string | null;
  amount?: number;
  balance?: number;
  onHoldAmount?: number;
  onHoldBalance?: number;
  availableBalance?: number;
  availableAmount?: number;
  percentageOfTotal?: number;
}

export interface PortfolioResponse {
  userId?: string | null;
  currencyId?: string | null;
  portfolioItems?: PortfolioItemResponse[] | null;
  totalBalance?: number;
  totalOnHoldBalance?: number;
  availableBalance?: number;
}

export interface Paging {
  page?: number;
  per_page?: number;
  total?: number;
  total_elements?: number;
}

export type OrderType = typeof OrderType[keyof typeof OrderType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderType = {
  Limit: 'Limit',
  Market: 'Market',
  StopLimit: 'StopLimit',
  StopMarket: 'StopMarket',
  UnPaid: 'UnPaid',
} as const;

export type OrderStatus = typeof OrderStatus[keyof typeof OrderStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderStatus = {
  Working: 'Working',
  Rejected: 'Rejected',
  Cancelled: 'Cancelled',
  Completed: 'Completed',
  Expired: 'Expired',
  WaitingForPayment: 'WaitingForPayment',
  Pending: 'Pending',
  Sending: 'Sending',
} as const;

export type OrderSide = typeof OrderSide[keyof typeof OrderSide];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderSide = {
  Buy: 'Buy',
  Sell: 'Sell',
} as const;

export type OrderInfoResponseFilteredResponseFilters = unknown | null;

export interface OrderInfoResponse {
  orderId?: string | null;
  total?: number;
  orderType?: OrderType;
  commission?: number;
  createdAt?: string;
  unitsFilled?: number;
  isPending?: boolean;
  status?: OrderStatus;
  cancelReason?: CancelReason;
  rejectDetails?: string | null;
  type?: string | null;
  amount?: number;
  remaining?: number;
  price?: number;
  executionPrice?: number;
  stopPrice?: number;
  isLimit?: boolean;
  instrument?: string | null;
  side?: OrderSide;
}

export interface OrderResponse {
  order?: OrderInfoResponse;
}

export interface OrderInfoResponseFilteredResponse {
  filters?: OrderInfoResponseFilteredResponseFilters;
  paging?: Paging;
  data?: OrderInfoResponse[] | null;
}

export interface OrderInfoRequest {
  instrument?: string | null;
  type?: string | null;
  amount?: number;
  price?: number;
  activationPrice?: number;
  isLimit?: boolean;
  isStop?: boolean;
  selfMatchStrategy?: SelfMatchPreventionStrategy;
  selfMatchToken?: string | null;
}

export interface OrderRequest {
  order?: OrderInfoRequest;
}

export interface MarketTierEntity {
  id?: number;
  name?: string | null;
  makerFee?: number;
  takerFee?: number;
}

export interface MarketInfo {
  baseAsset?: string | null;
  quoteAsset?: string | null;
  minPrice?: number;
  maxPrice?: number;
  minAmount?: number;
  hidden?: number;
  fee?: number;
  makerFee?: number;
  makerFeeLimit?: number;
  takerFee?: number;
  takerFeeLimit?: number;
  priceScale?: number;
  amountScale?: number;
  status?: ApiMarketStatus;
}

export type KycCallbackRequestInfos = {
  IDCardFullName?: string;
  IDCardDob?: string;
  IDCardExpiryDate?: string;
  IDCardIssueDate?: string;
  IDCardDocumentNumber?: string;
  IDCardGender?: string;
  IDCardCountry?: string;
  IDCardDocumentPhoto?: string;
  IDCardFacePhoto?: string;
  PassportFullName?: string;
  PassportDob?: string;
  PassportExpiryDate?: string;
  PassportIssueDate?: string;
  PassportDocumentNumber?: string;
  PassportGender?: string;
  PassportCountry?: string;
  PassportDocumentPhoto?: string;
  PassportFacePhoto?: string;
  ResidencePermitFullName?: string;
  ResidencePermitDob?: string;
  ResidencePermitExpiryDate?: string;
  ResidencePermitIssueDate?: string;
  ResidencePermitDocumentNumber?: string;
  ResidencePermitGender?: string;
  ResidencePermitCountry?: string;
  ResidencePermitDocumentPhoto?: string;
  ResidencePermitFacePhoto?: string;
  DriverLicenseFullName?: string;
  DriverLicenseDob?: string;
  DriverLicenseExpiryDate?: string;
  DriverLicenseIssueDate?: string;
  DriverLicenseDocumentNumber?: string;
  DriverLicenseGender?: string;
  DriverLicenseCountry?: string;
  DriverLicenseDocumentPhoto?: string;
  DriverLicenseFacePhoto?: string;
  Email?: string;
  Phone?: string;
} | null;

export interface KycCallbackRequest {
  user?: string | null;
  infos?: KycCallbackRequestInfos;
  error?: string | null;
}

export interface Int32Response {
  data?: number;
}

export type InfoResponsePairs = { [key: string]: MarketInfo } | null;

export interface InfoResponse {
  serverTime?: number;
  pairs?: InfoResponsePairs;
}

/**
 * Information about processed funds deposit request.
 */
export interface DepositFundsResponse {
  asset?: string | null;
  paymentSystem?: string | null;
  address?: string | null;
}

export interface DepositFundsRequest {
  assetId?: string | null;
  paymentSystem?: string | null;
  amount?: number | null;
  fiatPaymentMethod?: string | null;
}

export interface ContactFiatRecord {
  id?: string;
  name?: string | null;
  iban?: string | null;
  swift?: string | null;
  bankName?: string | null;
  bankAddressLine?: string | null;
  bankAddressLineOption?: string | null;
  bankCountry?: string | null;
  accountName?: string | null;
  accountSurname?: string | null;
  personalAddress?: string | null;
  personalAddressOptional?: string | null;
  country?: string | null;
  phoneNumber?: string | null;
  email?: string | null;
  dateCreated?: string;
}

export interface ContactFiatData {
  id?: string;
  name?: string | null;
  iban?: string | null;
  swift?: string | null;
  bankName?: string | null;
  bankAddressLine?: string | null;
  bankAddressLineOption?: string | null;
  bankCountry?: string | null;
  accountName?: string | null;
  accountSurname?: string | null;
  personalAddress?: string | null;
  personalAddressOptional?: string | null;
  country?: string | null;
  phoneNumber?: string | null;
  email?: string | null;
  dateCreated?: string;
}

export interface ContactCryptoResponse {
  readonly id?: string;
  readonly accountName?: string | null;
  readonly accountSurname?: string | null;
  readonly assetId?: string | null;
  readonly email?: string | null;
  readonly wallet?: string | null;
  readonly dateCreated?: string;
}

export interface ContactCryptoRequest {
  accountName?: string | null;
  accountSurname?: string | null;
  assetId?: string | null;
  email?: string | null;
  wallet?: string | null;
}

export type CancelReason = typeof CancelReason[keyof typeof CancelReason];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CancelReason = {
  NotCancelled: 'NotCancelled',
  CancelledByTrader: 'CancelledByTrader',
  CancelledBySystem: 'CancelledBySystem',
  SelfMatchPrevention: 'SelfMatchPrevention',
} as const;

export interface BuildInfoResponse {
  buildDate?: string | null;
  buildBranch?: string | null;
  buildCommitHash?: string | null;
}

export interface BasicAccountInfo {
  id?: string;
  type?: AccountType;
  code?: string | null;
  tradeLimit?: number | null;
  tags?: TagInfo[] | null;
}

export interface AutoExchangeResponse {
  readonly assetId?: string | null;
  readonly instrument?: string | null;
}

export interface AutoExchangeRequest {
  assetId?: string | null;
  instrument?: string | null;
}

export interface AssetResponse {
  id?: string | null;
  can_deposit?: boolean;
  can_withdraw?: boolean;
  asset_name?: string | null;
  withdrawal_fee?: number | null;
  scale?: number | null;
  min_withdraw?: number | null;
  confirmation_count?: number | null;
  created_at?: string;
}

export interface AssetResponseListResponse {
  data?: AssetResponse[] | null;
}

export type GetQuickSwapParams = {
  FromAssetId: string;
  ToAssetId: string;
  Amount: number;
};

export interface QuickSwapResponse {
  rate?: number;
  currencyRate?: number;
  totalConversion?: number;
  userCurrency?: string;
}

/**
 * Market status reported in APIs. Corresponds to MyExchange.Common.Data.MarketStatus
 */
export type ApiMarketStatus = typeof ApiMarketStatus[keyof typeof ApiMarketStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApiMarketStatus = {
  Open: 'Open',
  Halted: 'Halted',
  Paused: 'Paused',
} as const;

export type AccountType = typeof AccountType[keyof typeof AccountType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountType = {
  CASH: 'CASH',
  MARGIN: 'MARGIN',
} as const;

export interface AccountAssetResponse {
  asset?: string | null;
  balance?: number;
}

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

export const getAllAsset = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<AssetResponseListResponse>(
    { url: `/frontoffice/api/assets-info`, method: 'get', signal },
    options
  );
};

export const getGetAllAssetQueryKey = () => [`/frontoffice/api/assets-info`];

export type GetAllAssetQueryResult = NonNullable<Awaited<ReturnType<typeof getAllAsset>>>;
export type GetAllAssetQueryError = ErrorType<unknown>;

export const useGetAllAsset = <TData = Awaited<ReturnType<typeof getAllAsset>>, TError = ErrorType<unknown>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getAllAsset>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllAssetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllAsset>>> = ({ signal }) =>
    getAllAsset(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAllAsset>>, TError, TData>(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};

export const listAutoExchange = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<AutoExchangeResponse[]>(
    { url: `/frontoffice/api/v2/autoexchange`, method: 'get', signal },
    options
  );
};

export const getListAutoExchangeQueryKey = () => [`/frontoffice/api/v2/autoexchange`];

export type ListAutoExchangeQueryResult = NonNullable<Awaited<ReturnType<typeof listAutoExchange>>>;
export type ListAutoExchangeQueryError = ErrorType<void>;

export const useListAutoExchange = <
  TData = Awaited<ReturnType<typeof listAutoExchange>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof listAutoExchange>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListAutoExchangeQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listAutoExchange>>> = ({ signal }) =>
    listAutoExchange(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof listAutoExchange>>, TError, TData>(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};

export const saveAutoExchange = (
  autoExchangeRequest: AutoExchangeRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/frontoffice/api/v2/autoexchange`,
      method: 'put',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: autoExchangeRequest,
    },
    options
  );
};

export type SaveAutoExchangeMutationResult = NonNullable<Awaited<ReturnType<typeof saveAutoExchange>>>;
export type SaveAutoExchangeMutationBody = AutoExchangeRequest;
export type SaveAutoExchangeMutationError = ErrorType<unknown>;

export const useSaveAutoExchange = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof saveAutoExchange>>,
    TError,
    { data: AutoExchangeRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof saveAutoExchange>>,
    { data: AutoExchangeRequest }
  > = props => {
    const { data } = props ?? {};

    return saveAutoExchange(data, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof saveAutoExchange>>, TError, { data: AutoExchangeRequest }, TContext>(
    mutationFn,
    mutationOptions
  );
};

export const getAllContactsCryptoContactCrypto = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ContactCryptoResponse[]>(
    { url: `/frontoffice/api/contactcrypto`, method: 'get', signal },
    options
  );
};

export const getGetAllContactsCryptoContactCryptoQueryKey = () => [`/frontoffice/api/contactcrypto`];

export type GetAllContactsCryptoContactCryptoQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllContactsCryptoContactCrypto>>
>;
export type GetAllContactsCryptoContactCryptoQueryError = ErrorType<void>;

export const useGetAllContactsCryptoContactCrypto = <
  TData = Awaited<ReturnType<typeof getAllContactsCryptoContactCrypto>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getAllContactsCryptoContactCrypto>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllContactsCryptoContactCryptoQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllContactsCryptoContactCrypto>>> = ({ signal }) =>
    getAllContactsCryptoContactCrypto(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAllContactsCryptoContactCrypto>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  );

  return {
    queryKey,
    ...query,
  };
};

export const createContactCrypto = (
  contactCryptoRequest: ContactCryptoRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/frontoffice/api/contactcrypto`,
      method: 'post',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: contactCryptoRequest,
    },
    options
  );
};

export type CreateContactCryptoMutationResult = NonNullable<Awaited<ReturnType<typeof createContactCrypto>>>;
export type CreateContactCryptoMutationBody = ContactCryptoRequest;
export type CreateContactCryptoMutationError = ErrorType<unknown>;

export const useCreateContactCrypto = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createContactCrypto>>,
    TError,
    { data: ContactCryptoRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createContactCrypto>>,
    { data: ContactCryptoRequest }
  > = props => {
    const { data } = props ?? {};

    return createContactCrypto(data, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof createContactCrypto>>, TError, { data: ContactCryptoRequest }, TContext>(
    mutationFn,
    mutationOptions
  );
};

export const getContactCryptoById = (
  id: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ContactCryptoResponse>(
    { url: `/frontoffice/api/contactcrypto/${id}`, method: 'get', signal },
    options
  );
};

export const getGetContactCryptoByIdQueryKey = (id: string) => [`/frontoffice/api/contactcrypto/${id}`];

export type GetContactCryptoByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getContactCryptoById>>>;
export type GetContactCryptoByIdQueryError = ErrorType<void>;

export const useGetContactCryptoById = <
  TData = Awaited<ReturnType<typeof getContactCryptoById>>,
  TError = ErrorType<void>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getContactCryptoById>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetContactCryptoByIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getContactCryptoById>>> = ({ signal }) =>
    getContactCryptoById(id, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getContactCryptoById>>, TError, TData>(queryKey, queryFn, {
    enabled: !!id,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

export const updateContactCrypto = (
  id: string,
  contactCryptoRequest: ContactCryptoRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ContactCryptoResponse>(
    {
      url: `/frontoffice/api/contactcrypto/${id}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: contactCryptoRequest,
    },
    options
  );
};

export type UpdateContactCryptoMutationResult = NonNullable<Awaited<ReturnType<typeof updateContactCrypto>>>;
export type UpdateContactCryptoMutationBody = ContactCryptoRequest;
export type UpdateContactCryptoMutationError = ErrorType<void>;

export const useUpdateContactCrypto = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateContactCrypto>>,
    TError,
    { id: string; data: ContactCryptoRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateContactCrypto>>,
    { id: string; data: ContactCryptoRequest }
  > = props => {
    const { id, data } = props ?? {};

    return updateContactCrypto(id, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof updateContactCrypto>>,
    TError,
    { id: string; data: ContactCryptoRequest },
    TContext
  >(mutationFn, mutationOptions);
};

export const deleteContactCrypto = (id: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/frontoffice/api/contactcrypto/${id}`, method: 'delete' }, options);
};

export type DeleteContactCryptoMutationResult = NonNullable<Awaited<ReturnType<typeof deleteContactCrypto>>>;

export type DeleteContactCryptoMutationError = ErrorType<unknown>;

export const useDeleteContactCrypto = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteContactCrypto>>, TError, { id: string }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteContactCrypto>>, { id: string }> = props => {
    const { id } = props ?? {};

    return deleteContactCrypto(id, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof deleteContactCrypto>>, TError, { id: string }, TContext>(
    mutationFn,
    mutationOptions
  );
};

export const updateContactFiat = (
  contactFiatData: ContactFiatData,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<ContactFiatRecord>(
    {
      url: `/frontoffice/api/contactfiat`,
      method: 'put',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: contactFiatData,
    },
    options
  );
};

export type UpdateContactFiatMutationResult = NonNullable<Awaited<ReturnType<typeof updateContactFiat>>>;
export type UpdateContactFiatMutationBody = ContactFiatData;
export type UpdateContactFiatMutationError = ErrorType<void>;

export const useUpdateContactFiat = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateContactFiat>>,
    TError,
    { data: ContactFiatData },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateContactFiat>>,
    { data: ContactFiatData }
  > = props => {
    const { data } = props ?? {};

    return updateContactFiat(data, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof updateContactFiat>>, TError, { data: ContactFiatData }, TContext>(
    mutationFn,
    mutationOptions
  );
};

export const deleteContactFiat = (id: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<ContactFiatRecord>({ url: `/frontoffice/api/contactfiat/${id}`, method: 'delete' }, options);
};

export type DeleteContactFiatMutationResult = NonNullable<Awaited<ReturnType<typeof deleteContactFiat>>>;

export type DeleteContactFiatMutationError = ErrorType<void>;

export const useDeleteContactFiat = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteContactFiat>>, TError, { id: string }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteContactFiat>>, { id: string }> = props => {
    const { id } = props ?? {};

    return deleteContactFiat(id, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof deleteContactFiat>>, TError, { id: string }, TContext>(
    mutationFn,
    mutationOptions
  );
};

export const getInfo = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<InfoResponse>({ url: `/frontoffice/api/info`, method: 'get', signal }, options);
};

export const getGetInfoQueryKey = () => [`/frontoffice/api/info`];

export type GetInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getInfo>>>;
export type GetInfoQueryError = ErrorType<unknown>;

export const useGetInfo = <TData = Awaited<ReturnType<typeof getInfo>>, TError = ErrorType<unknown>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getInfo>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetInfoQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getInfo>>> = ({ signal }) => getInfo(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getInfo>>, TError, TData>(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};

export const getTiersByMarketInfo = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<MarketTierEntity[]>({ url: `/frontoffice/api/market/tiers`, method: 'get', signal }, options);
};

export const getGetTiersByMarketInfoQueryKey = () => [`/frontoffice/api/market/tiers`];

export type GetTiersByMarketInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getTiersByMarketInfo>>>;
export type GetTiersByMarketInfoQueryError = ErrorType<unknown>;

export const useGetTiersByMarketInfo = <
  TData = Awaited<ReturnType<typeof getTiersByMarketInfo>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getTiersByMarketInfo>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTiersByMarketInfoQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTiersByMarketInfo>>> = ({ signal }) =>
    getTiersByMarketInfo(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getTiersByMarketInfo>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  );

  return {
    queryKey,
    ...query,
  };
};

export const getBuildInfo = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<BuildInfoResponse>({ url: `/frontoffice/api/build-info`, method: 'get', signal }, options);
};

export const getGetBuildInfoQueryKey = () => [`/frontoffice/api/build-info`];

export type GetBuildInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getBuildInfo>>>;
export type GetBuildInfoQueryError = ErrorType<unknown>;

export const useGetBuildInfo = <
  TData = Awaited<ReturnType<typeof getBuildInfo>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getBuildInfo>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBuildInfoQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBuildInfo>>> = ({ signal }) =>
    getBuildInfo(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getBuildInfo>>, TError, TData>(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};

export const callbackKyc = (
  kycCallbackRequest: KycCallbackRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/frontoffice/api/v2/kyc/callback`,
      method: 'post',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: kycCallbackRequest,
    },
    options
  );
};

export type CallbackKycMutationResult = NonNullable<Awaited<ReturnType<typeof callbackKyc>>>;
export type CallbackKycMutationBody = KycCallbackRequest;
export type CallbackKycMutationError = ErrorType<unknown>;

export const useCallbackKyc = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof callbackKyc>>,
    TError,
    { data: KycCallbackRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof callbackKyc>>, { data: KycCallbackRequest }> = props => {
    const { data } = props ?? {};

    return callbackKyc(data, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof callbackKyc>>, TError, { data: KycCallbackRequest }, TContext>(
    mutationFn,
    mutationOptions
  );
};

export const getPortfolio = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<PortfolioResponse>({ url: `/frontoffice/api/v2/portfolio`, method: 'get', signal }, options);
};

export const getGetPortfolioQueryKey = () => [`/frontoffice/api/v2/portfolio`];

export type GetPortfolioQueryResult = NonNullable<Awaited<ReturnType<typeof getPortfolio>>>;
export type GetPortfolioQueryError = ErrorType<void>;

export const useGetPortfolio = <TData = Awaited<ReturnType<typeof getPortfolio>>, TError = ErrorType<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getPortfolio>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPortfolioQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPortfolio>>> = ({ signal }) =>
    getPortfolio(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getPortfolio>>, TError, TData>(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};

export const getOrCreateProfile = (
  params?: GetOrCreateProfileParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ProfileResponse>({ url: `/frontoffice/api/profile`, method: 'get', signal, params }, options);
};

export const getGetOrCreateProfileQueryKey = (params?: GetOrCreateProfileParams) => [
  `/frontoffice/api/profile`,
  ...(params ? [params] : []),
];

export type GetOrCreateProfileQueryResult = NonNullable<Awaited<ReturnType<typeof getOrCreateProfile>>>;
export type GetOrCreateProfileQueryError = ErrorType<void>;

export const useGetOrCreateProfile = <TData = Awaited<ReturnType<typeof getOrCreateProfile>>, TError = ErrorType<void>>(
  params?: GetOrCreateProfileParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getOrCreateProfile>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOrCreateProfileQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrCreateProfile>>> = ({ signal }) =>
    getOrCreateProfile(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getOrCreateProfile>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  );

  return {
    queryKey,
    ...query,
  };
};

export const getReferral = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<ReferralResponse>({ url: `/frontoffice/api/v2/referral`, method: 'get', signal }, options);
};

export const getGetReferralQueryKey = () => [`/frontoffice/api/v2/referral`];

export type GetReferralQueryResult = NonNullable<Awaited<ReturnType<typeof getReferral>>>;
export type GetReferralQueryError = ErrorType<void>;

export const useGetReferral = <TData = Awaited<ReturnType<typeof getReferral>>, TError = ErrorType<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getReferral>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReferralQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReferral>>> = ({ signal }) =>
    getReferral(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getReferral>>, TError, TData>(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};

export const getRewardsReferral = (
  params?: GetRewardsReferralParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ReferralRewardHistoryResponseFilteredResponse>(
    { url: `/frontoffice/api/v2/referral/rewards`, method: 'get', signal, params },
    options
  );
};

export const getGetRewardsReferralQueryKey = (params?: GetRewardsReferralParams) => [
  `/frontoffice/api/v2/referral/rewards`,
  ...(params ? [params] : []),
];

export type GetRewardsReferralQueryResult = NonNullable<Awaited<ReturnType<typeof getRewardsReferral>>>;
export type GetRewardsReferralQueryError = ErrorType<void>;

export const useGetRewardsReferral = <TData = Awaited<ReturnType<typeof getRewardsReferral>>, TError = ErrorType<void>>(
  params?: GetRewardsReferralParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getRewardsReferral>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetRewardsReferralQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRewardsReferral>>> = ({ signal }) =>
    getRewardsReferral(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getRewardsReferral>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  );

  return {
    queryKey,
    ...query,
  };
};

export const getReferralStatus = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<ReferralStatusResponse>(
    { url: `/frontoffice/api/v2/referral/status`, method: 'get', signal },
    options
  );
};

export const getGetReferralStatusQueryKey = () => [`/frontoffice/api/v2/referral/status`];

export type GetReferralStatusQueryResult = NonNullable<Awaited<ReturnType<typeof getReferralStatus>>>;
export type GetReferralStatusQueryError = ErrorType<void>;

export const useGetReferralStatus = <
  TData = Awaited<ReturnType<typeof getReferralStatus>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getReferralStatus>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReferralStatusQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReferralStatus>>> = ({ signal }) =>
    getReferralStatus(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getReferralStatus>>, TError, TData>(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};

export const createTrade = (orderRequest: OrderRequest, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<OrderResponse>(
    {
      url: `/frontoffice/api/order`,
      method: 'post',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: orderRequest,
    },
    options
  );
};

export type CreateTradeMutationResult = NonNullable<Awaited<ReturnType<typeof createTrade>>>;
export type CreateTradeMutationBody = OrderRequest;
export type CreateTradeMutationError = ErrorType<void>;

export const useCreateTrade = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof createTrade>>, TError, { data: OrderRequest }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTrade>>, { data: OrderRequest }> = props => {
    const { data } = props ?? {};

    return createTrade(data, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof createTrade>>, TError, { data: OrderRequest }, TContext>(
    mutationFn,
    mutationOptions
  );
};

export const cancelTrade = (orderId: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<OrderResponse>({ url: `/frontoffice/api/orders/${orderId}`, method: 'delete' }, options);
};

export type CancelTradeMutationResult = NonNullable<Awaited<ReturnType<typeof cancelTrade>>>;

export type CancelTradeMutationError = ErrorType<void>;

export const useCancelTrade = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof cancelTrade>>, TError, { orderId: string }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof cancelTrade>>, { orderId: string }> = props => {
    const { orderId } = props ?? {};

    return cancelTrade(orderId, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof cancelTrade>>, TError, { orderId: string }, TContext>(
    mutationFn,
    mutationOptions
  );
};

export const myTrade = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<OrderInfoResponse[]>({ url: `/frontoffice/api/orders/my`, method: 'get', signal }, options);
};

export const getMyTradeQueryKey = () => [`/frontoffice/api/orders/my`];

export type MyTradeQueryResult = NonNullable<Awaited<ReturnType<typeof myTrade>>>;
export type MyTradeQueryError = ErrorType<void>;

export const useMyTrade = <TData = Awaited<ReturnType<typeof myTrade>>, TError = ErrorType<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof myTrade>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getMyTradeQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof myTrade>>> = ({ signal }) => myTrade(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof myTrade>>, TError, TData>(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};

/**
 * @summary Tries to cancel all user's active orders.

On success reports the number of cancelled orders.
 */
export const cancelAllActiveOrdersTrade = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<Int32Response>({ url: `/frontoffice/api/orders`, method: 'delete' }, options);
};

export type CancelAllActiveOrdersTradeMutationResult = NonNullable<
  Awaited<ReturnType<typeof cancelAllActiveOrdersTrade>>
>;

export type CancelAllActiveOrdersTradeMutationError = ErrorType<void>;

export const useCancelAllActiveOrdersTrade = <
  TError = ErrorType<void>,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof cancelAllActiveOrdersTrade>>, TError, TVariables, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof cancelAllActiveOrdersTrade>>, TVariables> = () => {
    return cancelAllActiveOrdersTrade(requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof cancelAllActiveOrdersTrade>>, TError, TVariables, TContext>(
    mutationFn,
    mutationOptions
  );
};

export const orderHistoryTrade = (
  params?: OrderHistoryTradeParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<OrderInfoResponseFilteredResponse>(
    { url: `/frontoffice/api/order_history`, method: 'get', signal, params },
    options
  );
};

export const getOrderHistoryTradeQueryKey = (params?: OrderHistoryTradeParams) => [
  `/frontoffice/api/order_history`,
  ...(params ? [params] : []),
];

export type OrderHistoryTradeQueryResult = NonNullable<Awaited<ReturnType<typeof orderHistoryTrade>>>;
export type OrderHistoryTradeQueryError = ErrorType<void>;

export const useOrderHistoryTrade = <TData = Awaited<ReturnType<typeof orderHistoryTrade>>, TError = ErrorType<void>>(
  params?: OrderHistoryTradeParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof orderHistoryTrade>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getOrderHistoryTradeQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof orderHistoryTrade>>> = ({ signal }) =>
    orderHistoryTrade(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof orderHistoryTrade>>, TError, TData>(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};

export const getTradeHistory = (
  params?: GetTradeHistoryParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<TradeHistoryItemFilteredResponse>(
    { url: `/frontoffice/api/trade_history`, method: 'get', signal, params },
    options
  );
};

export const getGetTradeHistoryQueryKey = (params?: GetTradeHistoryParams) => [
  `/frontoffice/api/trade_history`,
  ...(params ? [params] : []),
];

export type GetTradeHistoryQueryResult = NonNullable<Awaited<ReturnType<typeof getTradeHistory>>>;
export type GetTradeHistoryQueryError = ErrorType<void>;

export const useGetTradeHistory = <TData = Awaited<ReturnType<typeof getTradeHistory>>, TError = ErrorType<void>>(
  params?: GetTradeHistoryParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTradeHistory>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTradeHistoryQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTradeHistory>>> = ({ signal }) =>
    getTradeHistory(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getTradeHistory>>, TError, TData>(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};

export const fullAssetsTrade = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<AccountAssetResponse[]>({ url: `/frontoffice/api/assets`, method: 'get', signal }, options);
};

export const getFullAssetsTradeQueryKey = () => [`/frontoffice/api/assets`];

export type FullAssetsTradeQueryResult = NonNullable<Awaited<ReturnType<typeof fullAssetsTrade>>>;
export type FullAssetsTradeQueryError = ErrorType<void>;

export const useFullAssetsTrade = <
  TData = Awaited<ReturnType<typeof fullAssetsTrade>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof fullAssetsTrade>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFullAssetsTradeQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fullAssetsTrade>>> = ({ signal }) =>
    fullAssetsTrade(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof fullAssetsTrade>>, TError, TData>(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};

export const swapTrade = (
  params?: SwapTradeParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<SwapResponse>({ url: `/frontoffice/api/swap`, method: 'get', signal, params }, options);
};

export const getSwapTradeQueryKey = (params?: SwapTradeParams) => [
  `/frontoffice/api/swap`,
  ...(params ? [params] : []),
];

export type SwapTradeQueryResult = NonNullable<Awaited<ReturnType<typeof swapTrade>>>;
export type SwapTradeQueryError = ErrorType<void>;

export const useSwapTrade = <TData = Awaited<ReturnType<typeof swapTrade>>, TError = ErrorType<void>>(
  params?: SwapTradeParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof swapTrade>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSwapTradeQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof swapTrade>>> = ({ signal }) =>
    swapTrade(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof swapTrade>>, TError, TData>(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};

export const callbackTwoFactorAuth = (
  twoFactorAuthCallbackRequest: TwoFactorAuthCallbackRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/frontoffice/api/v2/2fa`,
      method: 'post',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: twoFactorAuthCallbackRequest,
    },
    options
  );
};

export type CallbackTwoFactorAuthMutationResult = NonNullable<Awaited<ReturnType<typeof callbackTwoFactorAuth>>>;
export type CallbackTwoFactorAuthMutationBody = TwoFactorAuthCallbackRequest;
export type CallbackTwoFactorAuthMutationError = ErrorType<unknown>;

export const useCallbackTwoFactorAuth = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof callbackTwoFactorAuth>>,
    TError,
    { data: TwoFactorAuthCallbackRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof callbackTwoFactorAuth>>,
    { data: TwoFactorAuthCallbackRequest }
  > = props => {
    const { data } = props ?? {};

    return callbackTwoFactorAuth(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof callbackTwoFactorAuth>>,
    TError,
    { data: TwoFactorAuthCallbackRequest },
    TContext
  >(mutationFn, mutationOptions);
};

export const userSettingsGet = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<UserSettingsResponseResponse>(
    { url: `/frontoffice/api/settings`, method: 'get', signal },
    options
  );
};

export const getUserSettingsGetQueryKey = () => [`/frontoffice/api/settings`];

export type UserSettingsGetQueryResult = NonNullable<Awaited<ReturnType<typeof userSettingsGet>>>;
export type UserSettingsGetQueryError = ErrorType<void>;

export const useUserSettingsGet = <
  TData = Awaited<ReturnType<typeof userSettingsGet>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof userSettingsGet>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUserSettingsGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof userSettingsGet>>> = ({ signal }) =>
    userSettingsGet(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof userSettingsGet>>, TError, TData>(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};

export const userSettingsUpdate = (
  profileSettingsUpdateRequest: ProfileSettingsUpdateRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/frontoffice/api/setting`,
      method: 'put',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: profileSettingsUpdateRequest,
    },
    options
  );
};

export type UserSettingsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof userSettingsUpdate>>>;
export type UserSettingsUpdateMutationBody = ProfileSettingsUpdateRequest;
export type UserSettingsUpdateMutationError = ErrorType<unknown>;

export const useUserSettingsUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userSettingsUpdate>>,
    TError,
    { data: ProfileSettingsUpdateRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userSettingsUpdate>>,
    { data: ProfileSettingsUpdateRequest }
  > = props => {
    const { data } = props ?? {};

    return userSettingsUpdate(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof userSettingsUpdate>>,
    TError,
    { data: ProfileSettingsUpdateRequest },
    TContext
  >(mutationFn, mutationOptions);
};

export const userSettingsDelete = (
  profileSettingsUpdateRequest: ProfileSettingsUpdateRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/frontoffice/api/setting`,
      method: 'delete',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: profileSettingsUpdateRequest,
    },
    options
  );
};

export type UserSettingsDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof userSettingsDelete>>>;
export type UserSettingsDeleteMutationBody = ProfileSettingsUpdateRequest;
export type UserSettingsDeleteMutationError = ErrorType<unknown>;

export const useUserSettingsDelete = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userSettingsDelete>>,
    TError,
    { data: ProfileSettingsUpdateRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userSettingsDelete>>,
    { data: ProfileSettingsUpdateRequest }
  > = props => {
    const { data } = props ?? {};

    return userSettingsDelete(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof userSettingsDelete>>,
    TError,
    { data: ProfileSettingsUpdateRequest },
    TContext
  >(mutationFn, mutationOptions);
};

export const toggleSubscriptionUserTier = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/frontoffice/api/v2/usertiers`, method: 'post' }, options);
};

export type ToggleSubscriptionUserTierMutationResult = NonNullable<
  Awaited<ReturnType<typeof toggleSubscriptionUserTier>>
>;

export type ToggleSubscriptionUserTierMutationError = ErrorType<unknown>;

export const useToggleSubscriptionUserTier = <
  TError = ErrorType<unknown>,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof toggleSubscriptionUserTier>>, TError, TVariables, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof toggleSubscriptionUserTier>>, TVariables> = () => {
    return toggleSubscriptionUserTier(requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof toggleSubscriptionUserTier>>, TError, TVariables, TContext>(
    mutationFn,
    mutationOptions
  );
};

export const depositWallet = (
  depositFundsRequest: DepositFundsRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<DepositFundsResponse>(
    {
      url: `/frontoffice/api/wallet/deposit`,
      method: 'post',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: depositFundsRequest,
    },
    options
  );
};

export type DepositWalletMutationResult = NonNullable<Awaited<ReturnType<typeof depositWallet>>>;
export type DepositWalletMutationBody = DepositFundsRequest;
export type DepositWalletMutationError = ErrorType<void>;

export const useDepositWallet = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof depositWallet>>,
    TError,
    { data: DepositFundsRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof depositWallet>>,
    { data: DepositFundsRequest }
  > = props => {
    const { data } = props ?? {};

    return depositWallet(data, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof depositWallet>>, TError, { data: DepositFundsRequest }, TContext>(
    mutationFn,
    mutationOptions
  );
};

export const withdrawalWallet = (
  withdrawalRequest: WithdrawalRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<string>(
    {
      url: `/frontoffice/api/wallet/withdrawal`,
      method: 'post',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: withdrawalRequest,
    },
    options
  );
};

export type WithdrawalWalletMutationResult = NonNullable<Awaited<ReturnType<typeof withdrawalWallet>>>;
export type WithdrawalWalletMutationBody = WithdrawalRequest;
export type WithdrawalWalletMutationError = ErrorType<void>;

export const useWithdrawalWallet = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof withdrawalWallet>>,
    TError,
    { data: WithdrawalRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof withdrawalWallet>>,
    { data: WithdrawalRequest }
  > = props => {
    const { data } = props ?? {};

    return withdrawalWallet(data, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof withdrawalWallet>>, TError, { data: WithdrawalRequest }, TContext>(
    mutationFn,
    mutationOptions
  );
};

export const getTransferHistoryWallet = (
  params?: GetTransferHistoryWalletParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<TransferHistoryItemFilteredResponse>(
    { url: `/frontoffice/api/wallet/transfer_history`, method: 'get', signal, params },
    options
  );
};

export const getGetTransferHistoryWalletQueryKey = (params?: GetTransferHistoryWalletParams) => [
  `/frontoffice/api/wallet/transfer_history`,
  ...(params ? [params] : []),
];

export type GetTransferHistoryWalletQueryResult = NonNullable<Awaited<ReturnType<typeof getTransferHistoryWallet>>>;
export type GetTransferHistoryWalletQueryError = ErrorType<void>;

export const useGetTransferHistoryWallet = <
  TData = Awaited<ReturnType<typeof getTransferHistoryWallet>>,
  TError = ErrorType<void>
>(
  params?: GetTransferHistoryWalletParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTransferHistoryWallet>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTransferHistoryWalletQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransferHistoryWallet>>> = ({ signal }) =>
    getTransferHistoryWallet(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getTransferHistoryWallet>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  );

  return {
    queryKey,
    ...query,
  };
};

export const getWithdrawalLimit = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<WithdrawalLimitResponse>(
    { url: `/frontoffice/api/wallet/withdrawal/limits`, method: 'get', signal },
    options
  );
};

export const getGetWithdrawalLimitQueryKey = () => [`/frontoffice/api/wallet/withdrawal/limits`];

export type GetWithdrawalLimitQueryResult = NonNullable<Awaited<ReturnType<typeof getWithdrawalLimit>>>;
export type GetWithdrawalLimitQueryError = ErrorType<void>;

export const useGetWithdrawalLimit = <
  TData = Awaited<ReturnType<typeof getWithdrawalLimit>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getWithdrawalLimit>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetWithdrawalLimitQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getWithdrawalLimit>>> = ({ signal }) =>
    getWithdrawalLimit(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getWithdrawalLimit>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  );

  return {
    queryKey,
    ...query,
  };
};

export const getAllTagsAccountTags = (
  accountId: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<AccountTagData[]>(
    { url: `/back-api/api/v2/accounts/${accountId}/tags`, method: 'get', signal },
    options
  );
};

export const getGetAllTagsAccountTagsQueryKey = (accountId: string) => [`/back-api/api/v2/accounts/${accountId}/tags`];

export type GetAllTagsAccountTagsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllTagsAccountTags>>>;
export type GetAllTagsAccountTagsQueryError = ErrorType<void>;

export const useGetAllTagsAccountTags = <
  TData = Awaited<ReturnType<typeof getAllTagsAccountTags>>,
  TError = ErrorType<void>
>(
  accountId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAllTagsAccountTags>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllTagsAccountTagsQueryKey(accountId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllTagsAccountTags>>> = ({ signal }) =>
    getAllTagsAccountTags(accountId, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAllTagsAccountTags>>, TError, TData>(queryKey, queryFn, {
    enabled: !!accountId,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

export const createTagAccountTags = (
  accountId: string,
  accountTagData: AccountTagData,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<AccountTagData[]>(
    {
      url: `/back-api/api/v2/accounts/${accountId}/tags`,
      method: 'post',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: accountTagData,
    },
    options
  );
};

export type CreateTagAccountTagsMutationResult = NonNullable<Awaited<ReturnType<typeof createTagAccountTags>>>;
export type CreateTagAccountTagsMutationBody = AccountTagData;
export type CreateTagAccountTagsMutationError = ErrorType<void>;

export const useCreateTagAccountTags = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTagAccountTags>>,
    TError,
    { accountId: string; data: AccountTagData },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createTagAccountTags>>,
    { accountId: string; data: AccountTagData }
  > = props => {
    const { accountId, data } = props ?? {};

    return createTagAccountTags(accountId, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof createTagAccountTags>>,
    TError,
    { accountId: string; data: AccountTagData },
    TContext
  >(mutationFn, mutationOptions);
};

export const deleteTagAccountTags = (
  accountId: string,
  tagId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<AccountTagData[]>(
    { url: `/back-api/api/v2/accounts/${accountId}/tags/${tagId}`, method: 'delete' },
    options
  );
};

export type DeleteTagAccountTagsMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTagAccountTags>>>;

export type DeleteTagAccountTagsMutationError = ErrorType<void>;

export const useDeleteTagAccountTags = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTagAccountTags>>,
    TError,
    { accountId: string; tagId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteTagAccountTags>>,
    { accountId: string; tagId: number }
  > = props => {
    const { accountId, tagId } = props ?? {};

    return deleteTagAccountTags(accountId, tagId, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof deleteTagAccountTags>>,
    TError,
    { accountId: string; tagId: number },
    TContext
  >(mutationFn, mutationOptions);
};

export const deleteTagRecordAccountTags = (
  accountId: string,
  tagId: number,
  recordId: number,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<AccountTagData[]>(
    { url: `/back-api/api/v2/accounts/${accountId}/tags/${tagId}/${recordId}`, method: 'delete' },
    options
  );
};

export type DeleteTagRecordAccountTagsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteTagRecordAccountTags>>
>;

export type DeleteTagRecordAccountTagsMutationError = ErrorType<void>;

export const useDeleteTagRecordAccountTags = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTagRecordAccountTags>>,
    TError,
    { accountId: string; tagId: number; recordId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteTagRecordAccountTags>>,
    { accountId: string; tagId: number; recordId: number }
  > = props => {
    const { accountId, tagId, recordId } = props ?? {};

    return deleteTagRecordAccountTags(accountId, tagId, recordId, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof deleteTagRecordAccountTags>>,
    TError,
    { accountId: string; tagId: number; recordId: number },
    TContext
  >(mutationFn, mutationOptions);
};

export const getTagsInfoAccountTagsInfo = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<AccountTagInfo[]>({ url: `/back-api/api/v2/accounts/tags`, method: 'get', signal }, options);
};

export const getGetTagsInfoAccountTagsInfoQueryKey = () => [`/back-api/api/v2/accounts/tags`];

export type GetTagsInfoAccountTagsInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getTagsInfoAccountTagsInfo>>>;
export type GetTagsInfoAccountTagsInfoQueryError = ErrorType<void>;

export const useGetTagsInfoAccountTagsInfo = <
  TData = Awaited<ReturnType<typeof getTagsInfoAccountTagsInfo>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getTagsInfoAccountTagsInfo>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTagsInfoAccountTagsInfoQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTagsInfoAccountTagsInfo>>> = ({ signal }) =>
    getTagsInfoAccountTagsInfo(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getTagsInfoAccountTagsInfo>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  );

  return {
    queryKey,
    ...query,
  };
};

export const updateUserTierAdminUserTier = (
  adminUserTierRequest: AdminUserTierRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/back-api/api/v2/usertiers`,
      method: 'post',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: adminUserTierRequest,
    },
    options
  );
};

export type UpdateUserTierAdminUserTierMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateUserTierAdminUserTier>>
>;
export type UpdateUserTierAdminUserTierMutationBody = AdminUserTierRequest;
export type UpdateUserTierAdminUserTierMutationError = ErrorType<unknown>;

export const useUpdateUserTierAdminUserTier = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUserTierAdminUserTier>>,
    TError,
    { data: AdminUserTierRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateUserTierAdminUserTier>>,
    { data: AdminUserTierRequest }
  > = props => {
    const { data } = props ?? {};

    return updateUserTierAdminUserTier(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof updateUserTierAdminUserTier>>,
    TError,
    { data: AdminUserTierRequest },
    TContext
  >(mutationFn, mutationOptions);
};

export const updateAsset = (
  assetId: string,
  assetRequest: AssetRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<void>(
    {
      url: `/back-api/backoffice/asset/${assetId}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: assetRequest,
    },
    options
  );
};

export type UpdateAssetMutationResult = NonNullable<Awaited<ReturnType<typeof updateAsset>>>;
export type UpdateAssetMutationBody = AssetRequest;
export type UpdateAssetMutationError = ErrorType<unknown>;

export const useUpdateAsset = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAsset>>,
    TError,
    { assetId: string; data: AssetRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateAsset>>,
    { assetId: string; data: AssetRequest }
  > = props => {
    const { assetId, data } = props ?? {};

    return updateAsset(assetId, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof updateAsset>>,
    TError,
    { assetId: string; data: AssetRequest },
    TContext
  >(mutationFn, mutationOptions);
};

export const getAllBot = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<BotResponsePagedResponse>({ url: `/back-api/backoffice/bots`, method: 'get', signal }, options);
};

export const getGetAllBotQueryKey = () => [`/back-api/backoffice/bots`];

export type GetAllBotQueryResult = NonNullable<Awaited<ReturnType<typeof getAllBot>>>;
export type GetAllBotQueryError = ErrorType<void>;

export const useGetAllBot = <TData = Awaited<ReturnType<typeof getAllBot>>, TError = ErrorType<void>>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getAllBot>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllBotQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllBot>>> = ({ signal }) =>
    getAllBot(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAllBot>>, TError, TData>(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};

export const updateBot = (botId: string, botRequest: BotRequest, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<BotResponse>(
    {
      url: `/back-api/backoffice/bots/${botId}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: botRequest,
    },
    options
  );
};

export type UpdateBotMutationResult = NonNullable<Awaited<ReturnType<typeof updateBot>>>;
export type UpdateBotMutationBody = BotRequest;
export type UpdateBotMutationError = ErrorType<void>;

export const useUpdateBot = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateBot>>,
    TError,
    { botId: string; data: BotRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateBot>>,
    { botId: string; data: BotRequest }
  > = props => {
    const { botId, data } = props ?? {};

    return updateBot(botId, data, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof updateBot>>, TError, { botId: string; data: BotRequest }, TContext>(
    mutationFn,
    mutationOptions
  );
};

/**
 * @summary Get information about managed hot wallets.
 */
export const getInfoHotWallet = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<HotWalletInfoResponse[]>(
    { url: `/back-api/api/v2/wallets/hot`, method: 'get', signal },
    options
  );
};

export const getGetInfoHotWalletQueryKey = () => [`/back-api/api/v2/wallets/hot`];

export type GetInfoHotWalletQueryResult = NonNullable<Awaited<ReturnType<typeof getInfoHotWallet>>>;
export type GetInfoHotWalletQueryError = ErrorType<void>;

export const useGetInfoHotWallet = <
  TData = Awaited<ReturnType<typeof getInfoHotWallet>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getInfoHotWallet>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetInfoHotWalletQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getInfoHotWallet>>> = ({ signal }) =>
    getInfoHotWallet(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getInfoHotWallet>>, TError, TData>(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};

export const transferToColdWalletHotWallet = (
  coldWalletTransferRequest: ColdWalletTransferRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<TransactionEventData>(
    {
      url: `/back-api/api/v2/wallets/hot/withdrawal`,
      method: 'post',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: coldWalletTransferRequest,
    },
    options
  );
};

export type TransferToColdWalletHotWalletMutationResult = NonNullable<
  Awaited<ReturnType<typeof transferToColdWalletHotWallet>>
>;
export type TransferToColdWalletHotWalletMutationBody = ColdWalletTransferRequest;
export type TransferToColdWalletHotWalletMutationError = ErrorType<void>;

export const useTransferToColdWalletHotWallet = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof transferToColdWalletHotWallet>>,
    TError,
    { data: ColdWalletTransferRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof transferToColdWalletHotWallet>>,
    { data: ColdWalletTransferRequest }
  > = props => {
    const { data } = props ?? {};

    return transferToColdWalletHotWallet(data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof transferToColdWalletHotWallet>>,
    TError,
    { data: ColdWalletTransferRequest },
    TContext
  >(mutationFn, mutationOptions);
};

export const getTransferBalanceHotWallet = (
  params?: GetTransferBalanceHotWalletParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<number>(
    { url: `/back-api/api/v2/wallets/hot/transfers-balance`, method: 'get', signal, params },
    options
  );
};

export const getGetTransferBalanceHotWalletQueryKey = (params?: GetTransferBalanceHotWalletParams) => [
  `/back-api/api/v2/wallets/hot/transfers-balance`,
  ...(params ? [params] : []),
];

export type GetTransferBalanceHotWalletQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTransferBalanceHotWallet>>
>;
export type GetTransferBalanceHotWalletQueryError = ErrorType<void>;

export const useGetTransferBalanceHotWallet = <
  TData = Awaited<ReturnType<typeof getTransferBalanceHotWallet>>,
  TError = ErrorType<void>
>(
  params?: GetTransferBalanceHotWalletParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTransferBalanceHotWallet>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTransferBalanceHotWalletQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransferBalanceHotWallet>>> = ({ signal }) =>
    getTransferBalanceHotWallet(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getTransferBalanceHotWallet>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  );

  return {
    queryKey,
    ...query,
  };
};

export const updateMarket = (
  marketId: string,
  updateMarketRequest: UpdateMarketRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<MarketResponse>(
    {
      url: `/back-api/backoffice/market/${marketId}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: updateMarketRequest,
    },
    options
  );
};

export type UpdateMarketMutationResult = NonNullable<Awaited<ReturnType<typeof updateMarket>>>;
export type UpdateMarketMutationBody = UpdateMarketRequest;
export type UpdateMarketMutationError = ErrorType<void>;

export const useUpdateMarket = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateMarket>>,
    TError,
    { marketId: string; data: UpdateMarketRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateMarket>>,
    { marketId: string; data: UpdateMarketRequest }
  > = props => {
    const { marketId, data } = props ?? {};

    return updateMarket(marketId, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof updateMarket>>,
    TError,
    { marketId: string; data: UpdateMarketRequest },
    TContext
  >(mutationFn, mutationOptions);
};

export const getMarket = (marketId: string, options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<MarketResponse>(
    { url: `/back-api/backoffice/market/${marketId}`, method: 'get', signal },
    options
  );
};

export const getGetMarketQueryKey = (marketId: string) => [`/back-api/backoffice/market/${marketId}`];

export type GetMarketQueryResult = NonNullable<Awaited<ReturnType<typeof getMarket>>>;
export type GetMarketQueryError = ErrorType<void>;

export const useGetMarket = <TData = Awaited<ReturnType<typeof getMarket>>, TError = ErrorType<void>>(
  marketId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getMarket>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMarketQueryKey(marketId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMarket>>> = ({ signal }) =>
    getMarket(marketId, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMarket>>, TError, TData>(queryKey, queryFn, {
    enabled: !!marketId,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

/**
 * @summary Query a single order by id
 */
export const getOrderByIdOrders = (
  orderId: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<OrderDetails>({ url: `/back-api/api/v2/orders/${orderId}`, method: 'get', signal }, options);
};

export const getGetOrderByIdOrdersQueryKey = (orderId: string) => [`/back-api/api/v2/orders/${orderId}`];

export type GetOrderByIdOrdersQueryResult = NonNullable<Awaited<ReturnType<typeof getOrderByIdOrders>>>;
export type GetOrderByIdOrdersQueryError = ErrorType<ApiErrorResponse | void>;

export const useGetOrderByIdOrders = <
  TData = Awaited<ReturnType<typeof getOrderByIdOrders>>,
  TError = ErrorType<ApiErrorResponse | void>
>(
  orderId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getOrderByIdOrders>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOrderByIdOrdersQueryKey(orderId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrderByIdOrders>>> = ({ signal }) =>
    getOrderByIdOrders(orderId, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getOrderByIdOrders>>, TError, TData>(queryKey, queryFn, {
    enabled: !!orderId,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

/**
 * @summary Query orders that match the filter.
 */
export const ordersGetOrders = (
  params?: OrdersGetOrdersParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<OrdersResponse>({ url: `/back-api/api/v2/orders`, method: 'get', signal, params }, options);
};

export const getOrdersGetOrdersQueryKey = (params?: OrdersGetOrdersParams) => [
  `/back-api/api/v2/orders`,
  ...(params ? [params] : []),
];

export type OrdersGetOrdersQueryResult = NonNullable<Awaited<ReturnType<typeof ordersGetOrders>>>;
export type OrdersGetOrdersQueryError = ErrorType<ApiErrorResponse | void>;

export const useOrdersGetOrders = <
  TData = Awaited<ReturnType<typeof ordersGetOrders>>,
  TError = ErrorType<ApiErrorResponse | void>
>(
  params?: OrdersGetOrdersParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof ordersGetOrders>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getOrdersGetOrdersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof ordersGetOrders>>> = ({ signal }) =>
    ordersGetOrders(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof ordersGetOrders>>, TError, TData>(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};

export const ordersGetOrdersAsCsv = (
  params?: OrdersGetOrdersAsCsvParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<OrdersResponse>({ url: `/back-api/api/v2/orders.csv`, method: 'get', signal, params }, options);
};

export const getOrdersGetOrdersAsCsvQueryKey = (params?: OrdersGetOrdersAsCsvParams) => [
  `/back-api/api/v2/orders.csv`,
  ...(params ? [params] : []),
];

export type OrdersGetOrdersAsCsvQueryResult = NonNullable<Awaited<ReturnType<typeof ordersGetOrdersAsCsv>>>;
export type OrdersGetOrdersAsCsvQueryError = ErrorType<ApiErrorResponse | void>;

export const useOrdersGetOrdersAsCsv = <
  TData = Awaited<ReturnType<typeof ordersGetOrdersAsCsv>>,
  TError = ErrorType<ApiErrorResponse | void>
>(
  params?: OrdersGetOrdersAsCsvParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof ordersGetOrdersAsCsv>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getOrdersGetOrdersAsCsvQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof ordersGetOrdersAsCsv>>> = ({ signal }) =>
    ordersGetOrdersAsCsv(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof ordersGetOrdersAsCsv>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  );

  return {
    queryKey,
    ...query,
  };
};

export const cancelOrderOrders = (
  orderId: string,
  cancelOrderRequestPayload: CancelOrderRequestPayload,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<OrderDetails>(
    {
      url: `/back-api/api/v2/orders/${orderId}/status`,
      method: 'put',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: cancelOrderRequestPayload,
    },
    options
  );
};

export type CancelOrderOrdersMutationResult = NonNullable<Awaited<ReturnType<typeof cancelOrderOrders>>>;
export type CancelOrderOrdersMutationBody = CancelOrderRequestPayload;
export type CancelOrderOrdersMutationError = ErrorType<ApiErrorResponse | void>;

export const useCancelOrderOrders = <TError = ErrorType<ApiErrorResponse | void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cancelOrderOrders>>,
    TError,
    { orderId: string; data: CancelOrderRequestPayload },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof cancelOrderOrders>>,
    { orderId: string; data: CancelOrderRequestPayload }
  > = props => {
    const { orderId, data } = props ?? {};

    return cancelOrderOrders(orderId, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof cancelOrderOrders>>,
    TError,
    { orderId: string; data: CancelOrderRequestPayload },
    TContext
  >(mutationFn, mutationOptions);
};

export const getProfit = (
  params?: GetProfitParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ProfitResponse>({ url: `/back-api/backoffice/profit`, method: 'get', signal, params }, options);
};

export const getGetProfitQueryKey = (params?: GetProfitParams) => [
  `/back-api/backoffice/profit`,
  ...(params ? [params] : []),
];

export type GetProfitQueryResult = NonNullable<Awaited<ReturnType<typeof getProfit>>>;
export type GetProfitQueryError = ErrorType<void>;

export const useGetProfit = <TData = Awaited<ReturnType<typeof getProfit>>, TError = ErrorType<void>>(
  params?: GetProfitParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getProfit>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProfitQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProfit>>> = ({ signal }) =>
    getProfit(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getProfit>>, TError, TData>(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};

export const updateRole = (roleRequest: RoleRequest, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>(
    {
      url: `/back-api/api/v2/roles`,
      method: 'post',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: roleRequest,
    },
    options
  );
};

export type UpdateRoleMutationResult = NonNullable<Awaited<ReturnType<typeof updateRole>>>;
export type UpdateRoleMutationBody = RoleRequest;
export type UpdateRoleMutationError = ErrorType<unknown>;

export const useUpdateRole = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof updateRole>>, TError, { data: RoleRequest }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateRole>>, { data: RoleRequest }> = props => {
    const { data } = props ?? {};

    return updateRole(data, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof updateRole>>, TError, { data: RoleRequest }, TContext>(
    mutationFn,
    mutationOptions
  );
};

/**
 * @summary Query trades that match the filter.
 */
export const tradesGetOrders = (
  params?: TradesGetOrdersParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<TradesResponse>({ url: `/back-api/api/v2/trades`, method: 'get', signal, params }, options);
};

export const getTradesGetOrdersQueryKey = (params?: TradesGetOrdersParams) => [
  `/back-api/api/v2/trades`,
  ...(params ? [params] : []),
];

export type TradesGetOrdersQueryResult = NonNullable<Awaited<ReturnType<typeof tradesGetOrders>>>;
export type TradesGetOrdersQueryError = ErrorType<void>;

export const useTradesGetOrders = <TData = Awaited<ReturnType<typeof tradesGetOrders>>, TError = ErrorType<void>>(
  params?: TradesGetOrdersParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof tradesGetOrders>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getTradesGetOrdersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof tradesGetOrders>>> = ({ signal }) =>
    tradesGetOrders(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof tradesGetOrders>>, TError, TData>(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};

export const getAllTransfer = (
  accountId: number,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<TransferRecord[]>(
    { url: `/back-api/backoffice/transfers/${accountId}`, method: 'get', signal },
    options
  );
};

export const getGetAllTransferQueryKey = (accountId: number) => [`/back-api/backoffice/transfers/${accountId}`];

export type GetAllTransferQueryResult = NonNullable<Awaited<ReturnType<typeof getAllTransfer>>>;
export type GetAllTransferQueryError = ErrorType<void>;

export const useGetAllTransfer = <TData = Awaited<ReturnType<typeof getAllTransfer>>, TError = ErrorType<void>>(
  accountId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAllTransfer>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllTransferQueryKey(accountId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllTransfer>>> = ({ signal }) =>
    getAllTransfer(accountId, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAllTransfer>>, TError, TData>(queryKey, queryFn, {
    enabled: !!accountId,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

/**
 * @summary Query deposit/withdrawal details.
 */
export const getTransfers = (
  params?: GetTransfersParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<TransfersResponse>(
    { url: `/back-api/api/v2/transfers`, method: 'get', signal, params },
    options
  );
};

export const getGetTransfersQueryKey = (params?: GetTransfersParams) => [
  `/back-api/api/v2/transfers`,
  ...(params ? [params] : []),
];

export type GetTransfersQueryResult = NonNullable<Awaited<ReturnType<typeof getTransfers>>>;
export type GetTransfersQueryError = ErrorType<ApiErrorResponse | void>;

export const useGetTransfers = <
  TData = Awaited<ReturnType<typeof getTransfers>>,
  TError = ErrorType<ApiErrorResponse | void>
>(
  params?: GetTransfersParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTransfers>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTransfersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransfers>>> = ({ signal }) =>
    getTransfers(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getTransfers>>, TError, TData>(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};

/**
 * @summary Adds custom transfer to specified user account which allows to manually modify user balance.
 */
export const transferTransfers = (
  manualTransferRequest: ManualTransferRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<AdminManualTransferResponse>(
    {
      url: `/back-api/api/v2/transfers`,
      method: 'post',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: manualTransferRequest,
    },
    options
  );
};

export type TransferTransfersMutationResult = NonNullable<Awaited<ReturnType<typeof transferTransfers>>>;
export type TransferTransfersMutationBody = ManualTransferRequest;
export type TransferTransfersMutationError = ErrorType<void | ApiErrorResponse>;

export const useTransferTransfers = <TError = ErrorType<void | ApiErrorResponse>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof transferTransfers>>,
    TError,
    { data: ManualTransferRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof transferTransfers>>,
    { data: ManualTransferRequest }
  > = props => {
    const { data } = props ?? {};

    return transferTransfers(data, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof transferTransfers>>, TError, { data: ManualTransferRequest }, TContext>(
    mutationFn,
    mutationOptions
  );
};

export const getTransfersAsCsv = (
  params?: GetTransfersAsCsvParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<TransfersResponse>(
    { url: `/back-api/api/v2/transfers.csv`, method: 'get', signal, params },
    options
  );
};

export const getGetTransfersAsCsvQueryKey = (params?: GetTransfersAsCsvParams) => [
  `/back-api/api/v2/transfers.csv`,
  ...(params ? [params] : []),
];

export type GetTransfersAsCsvQueryResult = NonNullable<Awaited<ReturnType<typeof getTransfersAsCsv>>>;
export type GetTransfersAsCsvQueryError = ErrorType<ApiErrorResponse | void>;

export const useGetTransfersAsCsv = <
  TData = Awaited<ReturnType<typeof getTransfersAsCsv>>,
  TError = ErrorType<ApiErrorResponse | void>
>(
  params?: GetTransfersAsCsvParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTransfersAsCsv>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTransfersAsCsvQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransfersAsCsv>>> = ({ signal }) =>
    getTransfersAsCsv(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getTransfersAsCsv>>, TError, TData>(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};

export const getAllUser = (
  params?: GetAllUserParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<UserItemResponseFilteredResponse>(
    { url: `/back-api/backoffice/users`, method: 'get', signal, params },
    options
  );
};

export const getGetAllUserQueryKey = (params?: GetAllUserParams) => [
  `/back-api/backoffice/users`,
  ...(params ? [params] : []),
];

export type GetAllUserQueryResult = NonNullable<Awaited<ReturnType<typeof getAllUser>>>;
export type GetAllUserQueryError = ErrorType<void>;

export const useGetAllUser = <TData = Awaited<ReturnType<typeof getAllUser>>, TError = ErrorType<void>>(
  params?: GetAllUserParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAllUser>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllUserQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllUser>>> = ({ signal }) =>
    getAllUser(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAllUser>>, TError, TData>(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};

export const getUser = (userId: string, options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<UserResponseResponse>(
    { url: `/back-api/backoffice/user/${userId}`, method: 'get', signal },
    options
  );
};

export const getGetUserQueryKey = (userId: string) => [`/back-api/backoffice/user/${userId}`];

export type GetUserQueryResult = NonNullable<Awaited<ReturnType<typeof getUser>>>;
export type GetUserQueryError = ErrorType<void>;

export const useGetUser = <TData = Awaited<ReturnType<typeof getUser>>, TError = ErrorType<void>>(
  userId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserQueryKey(userId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUser>>> = ({ signal }) =>
    getUser(userId, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUser>>, TError, TData>(queryKey, queryFn, {
    enabled: !!userId,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

export const updateUser = (
  userId: string,
  updateUserRequest: UpdateUserRequest,
  options?: SecondParameter<typeof customInstance>
) => {
  return customInstance<UserResponse>(
    {
      url: `/back-api/backoffice/user/${userId}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: updateUserRequest,
    },
    options
  );
};

export type UpdateUserMutationResult = NonNullable<Awaited<ReturnType<typeof updateUser>>>;
export type UpdateUserMutationBody = UpdateUserRequest;
export type UpdateUserMutationError = ErrorType<void>;

export const useUpdateUser = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUser>>,
    TError,
    { userId: string; data: UpdateUserRequest },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateUser>>,
    { userId: string; data: UpdateUserRequest }
  > = props => {
    const { userId, data } = props ?? {};

    return updateUser(userId, data, requestOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof updateUser>>,
    TError,
    { userId: string; data: UpdateUserRequest },
    TContext
  >(mutationFn, mutationOptions);
};

export const blockUser = (userId: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/back-api/backoffice/user/${userId}/block`, method: 'put' }, options);
};

export type BlockUserMutationResult = NonNullable<Awaited<ReturnType<typeof blockUser>>>;

export type BlockUserMutationError = ErrorType<unknown>;

export const useBlockUser = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof blockUser>>, TError, { userId: string }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof blockUser>>, { userId: string }> = props => {
    const { userId } = props ?? {};

    return blockUser(userId, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof blockUser>>, TError, { userId: string }, TContext>(
    mutationFn,
    mutationOptions
  );
};

export const unlockUser = (userId: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/back-api/backoffice/user/${userId}/unblock`, method: 'put' }, options);
};

export type UnlockUserMutationResult = NonNullable<Awaited<ReturnType<typeof unlockUser>>>;

export type UnlockUserMutationError = ErrorType<unknown>;

export const useUnlockUser = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof unlockUser>>, TError, { userId: string }, TContext>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof unlockUser>>, { userId: string }> = props => {
    const { userId } = props ?? {};

    return unlockUser(userId, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof unlockUser>>, TError, { userId: string }, TContext>(
    mutationFn,
    mutationOptions
  );
};

/**
 * @summary Returns balances of all user accounts.
 */
export const getUserBalance = (
  userId: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<PortfolioResponse>(
    { url: `/back-api/api/v2/users/${userId}/balance`, method: 'get', signal },
    options
  );
};

export const getGetUserBalanceQueryKey = (userId: string) => [`/back-api/api/v2/users/${userId}/balance`];

export type GetUserBalanceQueryResult = NonNullable<Awaited<ReturnType<typeof getUserBalance>>>;
export type GetUserBalanceQueryError = ErrorType<void>;

export const useGetUserBalance = <TData = Awaited<ReturnType<typeof getUserBalance>>, TError = ErrorType<void>>(
  userId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getUserBalance>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserBalanceQueryKey(userId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserBalance>>> = ({ signal }) =>
    getUserBalance(userId, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserBalance>>, TError, TData>(queryKey, queryFn, {
    enabled: !!userId,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

export const getAllInstrumentsCharting = (options?: SecondParameter<typeof customInstance>, signal?: AbortSignal) => {
  return customInstance<string[]>({ url: `/marketdata/instruments`, method: 'get', signal }, options);
};

export const getGetAllInstrumentsChartingQueryKey = () => [`/marketdata/instruments`];

export type GetAllInstrumentsChartingQueryResult = NonNullable<Awaited<ReturnType<typeof getAllInstrumentsCharting>>>;
export type GetAllInstrumentsChartingQueryError = ErrorType<unknown>;

export const useGetAllInstrumentsCharting = <
  TData = Awaited<ReturnType<typeof getAllInstrumentsCharting>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getAllInstrumentsCharting>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllInstrumentsChartingQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllInstrumentsCharting>>> = ({ signal }) =>
    getAllInstrumentsCharting(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAllInstrumentsCharting>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  );

  return {
    queryKey,
    ...query,
  };
};

export const getHistoryCharting = (
  instrument: string,
  params?: GetHistoryChartingParams,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<ChartHistoryResponse>(
    { url: `/marketdata/instruments/${instrument}/history`, method: 'get', signal, params },
    options
  );
};

export const getGetHistoryChartingQueryKey = (instrument: string, params?: GetHistoryChartingParams) => [
  `/marketdata/instruments/${instrument}/history`,
  ...(params ? [params] : []),
];

export type GetHistoryChartingQueryResult = NonNullable<Awaited<ReturnType<typeof getHistoryCharting>>>;
export type GetHistoryChartingQueryError = ErrorType<unknown>;

export const useGetHistoryCharting = <
  TData = Awaited<ReturnType<typeof getHistoryCharting>>,
  TError = ErrorType<unknown>
>(
  instrument: string,
  params?: GetHistoryChartingParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getHistoryCharting>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetHistoryChartingQueryKey(instrument, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getHistoryCharting>>> = ({ signal }) =>
    getHistoryCharting(instrument, params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getHistoryCharting>>, TError, TData>(queryKey, queryFn, {
    enabled: !!instrument,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

export const getRateHistoryCharting = (
  asset: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<GetRateHistoryCharting200One | GetRateHistoryCharting200Two | GetRateHistoryCharting200Three>(
    { url: `/marketdata/instruments/history/rates/${asset}`, method: 'get', signal },
    options
  );
};

export const getGetRateHistoryChartingQueryKey = (asset: string) => [`/marketdata/instruments/history/rates/${asset}`];

export type GetRateHistoryChartingQueryResult = NonNullable<Awaited<ReturnType<typeof getRateHistoryCharting>>>;
export type GetRateHistoryChartingQueryError = ErrorType<unknown>;

export const useGetRateHistoryCharting = <
  TData = Awaited<ReturnType<typeof getRateHistoryCharting>>,
  TError = ErrorType<unknown>
>(
  asset: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getRateHistoryCharting>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetRateHistoryChartingQueryKey(asset);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRateHistoryCharting>>> = ({ signal }) =>
    getRateHistoryCharting(asset, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getRateHistoryCharting>>, TError, TData>(queryKey, queryFn, {
    enabled: !!asset,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

export const getBookInfoCharting = (
  instrument: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<OrderBookInfo>(
    { url: `/marketdata/instruments/${instrument}/depth`, method: 'get', signal },
    options
  );
};

export const getGetBookInfoChartingQueryKey = (instrument: string) => [`/marketdata/instruments/${instrument}/depth`];

export type GetBookInfoChartingQueryResult = NonNullable<Awaited<ReturnType<typeof getBookInfoCharting>>>;
export type GetBookInfoChartingQueryError = ErrorType<unknown>;

export const useGetBookInfoCharting = <
  TData = Awaited<ReturnType<typeof getBookInfoCharting>>,
  TError = ErrorType<unknown>
>(
  instrument: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getBookInfoCharting>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBookInfoChartingQueryKey(instrument);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBookInfoCharting>>> = ({ signal }) =>
    getBookInfoCharting(instrument, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getBookInfoCharting>>, TError, TData>(queryKey, queryFn, {
    enabled: !!instrument,
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

export const getExchangeRate = (
  instrument: string,
  dateTime: string,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal
) => {
  return customInstance<void>(
    { url: `/marketdata/exchange_rates/${instrument}/${dateTime}`, method: 'get', signal },
    options
  );
};

export const getGetExchangeRateQueryKey = (instrument: string, dateTime: string) => [
  `/marketdata/exchange_rates/${instrument}/${dateTime}`,
];

export type GetExchangeRateQueryResult = NonNullable<Awaited<ReturnType<typeof getExchangeRate>>>;
export type GetExchangeRateQueryError = ErrorType<unknown>;

export const useGetExchangeRate = <TData = Awaited<ReturnType<typeof getExchangeRate>>, TError = ErrorType<unknown>>(
  instrument: string,
  dateTime: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getExchangeRate>>, TError, TData>;
    request?: SecondParameter<typeof customInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetExchangeRateQueryKey(instrument, dateTime);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getExchangeRate>>> = ({ signal }) =>
    getExchangeRate(instrument, dateTime, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getExchangeRate>>, TError, TData>(queryKey, queryFn, {
    enabled: !!(instrument && dateTime),
    ...queryOptions,
  });

  return {
    queryKey,
    ...query,
  };
};

export const requestQuickSwap = (params?: GetQuickSwapParams, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<QuickSwapResponse>(
    {
      url: `/frontoffice/api/swap`,
      method: 'get',
      headers: { 'Content-Type': 'application/json-patch+json' },
      params,
    },
    options
  );
};

export type RequestQuickSwapMutationResult = NonNullable<Awaited<ReturnType<typeof requestQuickSwap>>>;
export type RequestQuickSwapMutationBody = OrderRequest;
export type RequestQuickSwapMutationError = ErrorType<void>;

export const useRequestQuickSwap = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof requestQuickSwap>>,
    TError,
    { params: GetQuickSwapParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof requestQuickSwap>>,
    { params: GetQuickSwapParams }
  > = props => {
    const { params } = props ?? {};

    return requestQuickSwap(params, requestOptions);
  };

  return useMutation<Awaited<ReturnType<typeof requestQuickSwap>>, TError, { params: GetQuickSwapParams }, TContext>(
    mutationFn,
    mutationOptions
  );
};
