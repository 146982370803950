import Avatar from '@ping/assets/Icon/avatar.svg';
import History from '@ping/assets/Icon/history.svg';
import LinkIcon from '@ping/assets/Icon/link.svg';
import Logout from '@ping/assets/Icon/logout.svg';
import Portfolio from '@ping/assets/Icon/portfolio.svg';
import Settings from '@ping/assets/Icon/settings.svg';
import Support from '@ping/assets/Icon/support.svg';
import Swap from '@ping/assets/Icon/swap.svg';
import Trending from '@ping/assets/Icon/trending.svg';
import { loginSideEffect } from '@ping/authorization/side-effects';
import { Deposit, LanguageDialog } from '@ping/components';
import CurrencyDropDown from '@ping/components/Header/CurrencyDropDown';
import NavbarDropdownItems, { TNavbarDropdownItem } from '@ping/components/Header/NavbarDropdownItems';
import NavbarLink from '@ping/components/Header/NavLink';
import { signoutReset } from '@ping/helpers';
import { useIsUserLoggedIn, useMobile } from '@ping/hooks';
import {
  isSelectedThemeDarkSelector,
  selectedLanguageSelector,
  usePreferencesStore,
} from '@ping/stores/preferences.store';
import { isUserAdminSelector, useUserInformationStore } from '@ping/stores/userInformation.store';
import { Popup } from '@ping/uikit';
import classnames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { MouseEvent, useState } from 'react';
import { Button, Nav, Navbar, NavDropdown } from 'react-bootstrap';

import style from './style.module.scss';

const Header = () => {
  const isUserLoggedIn = useIsUserLoggedIn();
  const [openLanguageDialog, setOpenLanguageDialog] = useState(false);
  const isSelectedThemeDark = usePreferencesStore(isSelectedThemeDarkSelector);
  const selectedLanguage = usePreferencesStore(selectedLanguageSelector);

  const [isDepositOpen, setIsDepositOpen] = useState(false);

  const handleLanguageChange = (value: boolean) => {
    setOpenLanguageDialog(value);
  };

  return (
    <header className={classnames(style['header'], 'light-bb')}>
      <Navbar expand='lg'>
        <Navbar.Brand>
          <Link href='/exchange'>
            <img className={style['logo']} src={`/img/logo-${isSelectedThemeDark ? 'dark' : 'light'}.svg`} alt='logo' />
          </Link>
        </Navbar.Brand>
        <Nav className={classnames(style['header__navbar'], style['header__links-nav'])}>
          <NavbarLink
            options={[
              { title: 'Exchange', href: '/', className: style['header__link'] },
              { title: 'Markets', href: '/markets', className: style['header__link'] },
            ]}
          />
          {isUserLoggedIn && (
            <a className={style['header__link']} onClick={() => setIsDepositOpen(true)}>
              Deposit
            </a>
          )}
        </Nav>
        <Nav className={classnames(style['header__navbar'], style['nav-user'])}>
          <span className={style['nav-language']} onClick={() => handleLanguageChange(true)}>
            {selectedLanguage}
          </span>
          <CurrencyDropDown />
          {isUserLoggedIn ? <ProfileDropdownMenu /> : <GetStartedButton />}
        </Nav>
      </Navbar>
      <LanguageDialog isOpen={openLanguageDialog} onClose={() => handleLanguageChange(false)} />
      <Popup isOpen={isDepositOpen} onClose={() => setIsDepositOpen(false)} title='Deposit'>
        <Deposit />
      </Popup>
    </header>
  );
};

const ProfileDropdownMenu = () => {
  const isUserAdmin = useUserInformationStore(isUserAdminSelector);
  const router = useRouter();
  const isMobile = useMobile();

  const handleLogout = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    router.push('/');
    signoutReset();
  };

  const navDropDownOptions: TNavbarDropdownItem[] = [
    {
      title: 'Portfolio',
      href: '/user/portfolio',
      icon: <Portfolio className={style['nav-item-icon']} />,
    },
    {
      title: 'History',
      href: '/user/history',
      icon: <History className={style['nav-item-icon']} />,
    },
    {
      title: 'Referrals',
      href: '/user/referrals',
      icon: <LinkIcon className={style['nav-item-icon']} />,
    },
    {
      title: 'Settings',
      href: '/user/account-settings',
      icon: <Settings className={style['nav-item-icon']} />,
    },
    {
      title: 'Support',
      href: 'http://support.pingextest.eu',
      icon: <Support className={style['nav-item-icon']} />,
    },
    ...(isUserAdmin
      ? [
          {
            title: 'Admin',
            href: '/admin',
            icon: <Avatar className={style['nav-item-icon']} />,
          },
        ]
      : ([] as any)),
    {
      title: 'Exchange',
      href: '/exchange',
      icon: <Swap className={style['nav-item-icon']} />,
      hasTopSeparator: true,
      isMobileOnly: true,
    },
    {
      title: 'Markets',
      href: '/markets',
      icon: <Trending className={style['nav-item-icon']} />,
      isMobileOnly: true,
    },
    {
      title: 'Log out',
      href: '',
      icon: <Logout className={style['nav-item-icon']} />,
      className: style['header__dropdown-item'],
      onClick: handleLogout,
    },
  ];

  return (
    <NavDropdown
      id={style['nav-dropdown-profile']}
      title={
        <img
          width={isMobile ? 18 : 20}
          height={isMobile ? 12 : 20}
          className={style['header__profile-image']}
          src={isMobile ? '/img/burgerMenu.svg' : '/img/avatar.svg'}
          alt='avatar'
        />
      }
    >
      <NavbarDropdownItems className={style['header__dropdown-item']} options={navDropDownOptions} />
    </NavDropdown>
  );
};

export const GetStartedButton: React.FC = () => (
  <Button className={style['header__get-started-button']} variant='primary' onClick={loginSideEffect}>
    Get Started
  </Button>
);

export default Header;
