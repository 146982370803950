import { Button, Popup, Text } from '@ping/uikit';

import style from './style.module.scss';

export interface IDoubleConfirmModalProps {
  title: React.ReactNode;
  description?: React.ReactNode;
  children?: React.ReactNode;
  show: boolean;
  confirmText: string;
  cancelText?: string;
  onConfirm: () => void;
  onClose: () => void;
  loading?: boolean;
}

const DoubleConfirmModal: React.FC<IDoubleConfirmModalProps> = ({
  title,
  description,
  children,
  show,
  confirmText,
  cancelText,
  onConfirm,
  onClose,
  loading,
}) => {
  return (
    <Popup className={style['confirmation-modal']} title={title} isOpen={show} onClose={onClose}>
      {description && <Text body='regular'>{description}</Text>}
      {Boolean(children) && <div className={style['confirmation-modal-content']}>{children}</div>}
      <div className={style['confirmation-actions']}>
        <Button className={style['action-item']} size='large' type='secondary' design='gray' onClick={onClose}>
          {cancelText || 'No, cancel'}
        </Button>
        <Button
          className={style['action-item']}
          size='large'
          type='primary'
          design='gray'
          onClick={onConfirm}
          disabled={loading}
        >
          {confirmText}
        </Button>
      </div>
    </Popup>
  );
};

export default DoubleConfirmModal;
