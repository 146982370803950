import CoinIcon from '@ping/uikit/CoinIcon';
import React, { useState } from 'react';

import style from './style.module.scss';

interface IProps {
  className?: string;
  coinName: string;
  coinFullName?: string;
}

const CoinItem = React.forwardRef<HTMLDivElement, IProps>((props, ref) => {
  const { className = '', coinName, coinFullName = '' } = props;
  const [showName, setShowName] = useState(true);

  return (
    <div ref={ref} className={`${style['coin-item']} ${className}`}>
      <CoinIcon className={style['coin-item__icon']} onError={() => setShowName(false)} name={coinName} />
      {showName && (
        <span className={style['coin-item__title']}>
          {coinName}
          {coinFullName ? ` - ${coinFullName}` : ''}
        </span>
      )}
    </div>
  );
});

export default CoinItem;
