import { Text } from '@ping/uikit';
import Warning from '@ping/assets/Icon/warning.svg';
import style from './style.module.scss';
import { useMemo } from 'react';

interface IProps {
  type: 'warning';
  message: string;
}

const Alert = ({ type, message }: IProps) => {
  const renderIcon = () => {
    switch (type) {
      case 'warning':
        return <Warning />;
    }
  };

  const alertTitle = useMemo(() => {
    switch (type) {
      case 'warning':
        return 'Warning';
    }
  }, [type]);

  return (
    <div className={style['alert']}>
      <div className={style['icon-wrapper']}>{renderIcon()}</div>
      <div className={style['content']}>
        <Text className={style['alert-title']} heading='3'>
          {alertTitle}
        </Text>
        <Text caption='regular'>{message}</Text>
      </div>
    </div>
  );
};

export default Alert;
