import { handleProfile } from '@ping/authorization/side-effects';
import { useUserInformationStore, isUserVerificationInProgressSelector } from './userInformation.store';

const FIVE_SECONDS = 5_000 as const;

/**
 * If the user is in the process of verifying their documents, then we want to check their profile
 * every 5 seconds to see if they've completed the verification process
 * @returns Unsubscribe function from the `useUserInformationStore` store
 */
export const checkUserVerification = () => {
  const checkUserVerificationListener = (isUserVerificationInProgress: boolean) => {
    let intervalId: ReturnType<typeof setInterval> = null;
    if (isUserVerificationInProgress) {
      intervalId = setInterval(handleProfile, FIVE_SECONDS);
    } else {
      clearInterval(intervalId);
    }
  };

  return useUserInformationStore.sliceSubscribe(isUserVerificationInProgressSelector, checkUserVerificationListener, {
    fireImmediately: true,
  });
};
