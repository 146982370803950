import { t } from '@ping/helpers';
import { isKYCedRequiredSelector, useUserInformationStore } from '@ping/stores/userInformation.store';
import { Alert } from '@ping/uikit';
import { useRouter } from 'next/router';

import Banner from './Banner';

const UserKYCRequiredBanner = () => {
  const router = useRouter();
  const handleStartKYCBtn = () => router.push('/kyc');
  const isKYCedRequired = useUserInformationStore(isKYCedRequiredSelector);

  return (
    <Banner
      show={isKYCedRequired}
      message={t("You need to verify your Email and one Passport, ID Card, Driver's License, or Residence Permit.")}
      modal={{
        title: t('KYC error'),
        cancelText: t('Cancel'),
        confirmText: t('Verify'),
        onConfirm: handleStartKYCBtn,
        children: (
          <Alert
            message={t(
              'Please keep in mind that this action is irreversible and once user is terminated this action can’t be undone.'
            )}
            type='warning'
          />
        ),
      }}
    />
  );
};

export default UserKYCRequiredBanner;
