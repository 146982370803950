import React, { FC, ReactElement } from 'react';
import { Dropdown, NavDropdown } from 'react-bootstrap';
import Link from 'next/link';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import style from './style.module.scss';

export type TNavbarDropdownItem = {
  href: string;
  title: string;
  icon: ReactElement;
  className: boolean;
  onClick?: () => void;
  hasTopSeparator?: boolean;
  hasBottomSeparator?: boolean;
  isMobileOnly?: boolean;
};

interface INavbarDropdownProps {
  options: TNavbarDropdownItem[];
  className: string;
}

export const NavbarDropdownItems: FC<INavbarDropdownProps> = props => {
  const { className = '', options } = props;
  const router = useRouter();

  return (
    <>
      {options.map((item, index) => (
        <NavDropdown.Item className={style['nav-item']} key={index + item.title}>
          {item.hasTopSeparator && <Dropdown.Divider />}
          <Link href={item.href}>
            <a
              onClick={item.onClick}
              className={classnames(className, item.className, {
                [style['is-mobile-only']]: item.isMobileOnly,
                [style['is-active']]: router.pathname === item.href,
              })}
            >
              {item.icon}
              <span>{item.title}</span>
            </a>
          </Link>
          {item.hasBottomSeparator && <Dropdown.Divider />}
        </NavDropdown.Item>
      ))}
    </>
  );
};

export default NavbarDropdownItems;
