import { Text } from '@ping/uikit';
import { disablePageScroll } from '@ping/utils';
import classNames from 'classnames/bind';
import { useEffect, useRef, useState } from 'react';
import SVG from 'react-inlinesvg';
import ReactModal from 'react-modal';

import style from './style.module.scss';

const cx = classNames.bind(style);

export interface IPopupProps extends ReactModal.Props {
  isOpen: boolean;
  title?: React.ReactNode;
  isHeaderVisible?: boolean;
  onClose?: () => void;
  onBack?: () => void;
  fullscreen?: boolean;
  children: React.ReactNode;
  contentClassName?: string;
}

const Popup: React.FC<IPopupProps> = props => {
  const {
    title,
    isOpen,
    isHeaderVisible = true,
    onClose,
    onBack,
    children,
    fullscreen,
    className,
    contentClassName,
    ...rest
  } = props;

  /* handling animation state */
  const [closing, setClosing] = useState<boolean>(false);
  const modalIdentifier = useRef(Math.random().toString(16));

  const closeTimeout = 100;

  const handleOnClose = () => {
    setClosing(true);
    setTimeout(onClose, closeTimeout);
  };
  const handleOnBack = () => {
    setClosing(true);
    setTimeout(onBack, closeTimeout);
  };

  useEffect(() => {
    disablePageScroll(isOpen, modalIdentifier.current);

    /* reset closing animation when is open trigger  again */
    isOpen && setClosing(false);
  }, [isOpen]);

  const contentClassNames = cx('popup__content', contentClassName, {
    ['popup__closing']: closing,
  });
  const headerClassName = cx('popup__header', {
    ['center']: Boolean(onBack),
  });

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleOnClose}
      className={cx('popup', {
        ['fullScreen']: fullscreen,
        [`${className}`]: className,
      })}
      overlayClassName={style['popup__overlay']}
      closeTimeoutMS={closeTimeout}
      ariaHideApp={false}
      {...rest}
    >
      <div className={contentClassNames} data-popup='popup'>
        {isHeaderVisible && (
          <div className={headerClassName}>
            {Boolean(onBack) && <SVG height={20} width={20} onClick={handleOnBack} src='/img/arrowLeft.svg' />}
            <div className={style['popup__left-part']}>{title && <Text heading='3'>{title}</Text>}</div>
            {Boolean(onClose) && <SVG height={32} width={32} onClick={handleOnClose} src='/img/close-icon.svg' />}
          </div>
        )}

        {children}
      </div>
    </ReactModal>
  );
};

export default Popup;
