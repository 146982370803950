import { ECurrency } from '@ping/enums';

/**
 * It takes a string or a Date object and returns a formatted date string
 * @param {string | Date} value - string | Date - The value to format.
 */
export const formatDate = (value: string | Date) => {
  if (!value) return null;

  const date = new Date(value);
  const formatted = {
    time: Intl.DateTimeFormat('default', { timeStyle: 'medium', hour12: false }).format(date),
    date: Intl.DateTimeFormat('default', { dateStyle: 'long' }).format(date),
  };

  return `${formatted.date} - ${formatted.time}`;
};

/**
 * It takes a number or string and returns a formatted string number
 * @param {number | string} value - The number to format.
 * @param [options] - Intl.NumberFormatOptions
 */
export const formatNumber = (value: number | string, options?: Intl.NumberFormatOptions) => {
  if (value === null || value === undefined) return null;

  return Intl.NumberFormat('default', options).format(Number(value));
};

/**
 * It formats a number as a crypto currency with a maximum of 6 decimal places
 * @param {number | string} value - number | string
 */
export const formatCrypto = (value: number | string, symbol: string) => {
  if (value === null || value === undefined) return null;

  const amount = formatNumber(value, {
    maximumFractionDigits: 6,
    minimumFractionDigits: 0,
  });

  return `${amount} ${symbol?.toUpperCase()}`;
};

/**
 * It formats a number as a fiat currency, using the currency code provided with a maximum of 2 decimal places
 * @param {number | string} value - The value to be formatted.
 * @param {ECurrency} currency - The currency to use.
 */
export const formatFiat = (value: number | string, currency: ECurrency = ECurrency.USD) => {
  if (value === null || value === undefined) return null;

  return formatNumber(value, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    style: 'currency',
    currency,
  });
};

export const format = {
  date: formatDate,
  number: formatNumber,
  crypto: formatCrypto,
  fiat: formatFiat,
};
