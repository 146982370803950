// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

/* eslint-disable */
const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_ENVIRONMENT = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT;
const SENTRY_TRACE_SAMPLE_RATE = process.env.NEXT_PUBLIC_SENTRY_TRACE_SAMPLE_RATE;
/* eslint-enable */

Sentry.init({
  dsn: SENTRY_DSN || 'null',
  environment: SENTRY_ENVIRONMENT || 'development',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: SENTRY_TRACE_SAMPLE_RATE || 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
