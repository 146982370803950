import React, { FC } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

type TNavbarLinkItem = {
  href?: string;
  title: string;
  className: string;
};

interface INavbarLinkProps {
  options: TNavbarLinkItem[];
}

const NavbarLink: FC<INavbarLinkProps> = ({ options }) => {
  const router = useRouter();

  return (
    <>
      {options.map((item, index) =>
        item.title === 'Deposit' ? (
          <a
            key={index + item.title}
            className={`${item.className} ${router.pathname === item.href ? 'nav-link-active' : ''}`}
          >
            {item.title}
          </a>
        ) : (
          <Link href={item.href} key={index + item.title}>
            <a className={`${item.className} ${router.pathname === item.href ? 'nav-link-active' : ''}`}>
              {item.title}
            </a>
          </Link>
        )
      )}
    </>
  );
};

export default NavbarLink;
