import ArrowDown from '@ping/assets/Icon/arrowDown.svg';
import SearchIcon from '@ping/assets/Icon/search.svg';
import useOutsideAlerter from '@ping/uikit/CoinSelect/clickOutsidehook';
import { debounce, isBrowser } from '@ping/utils';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import style from './style.module.scss';

export type ICoinValue<T = any> = { value: T; view: React.ReactNode };

export interface ICoinSelect extends Omit<React.HTMLAttributes<HTMLDivElement>, 'defaultValue' | 'onChange'> {
  options: ICoinValue[];
  value?: string | ICoinValue;
  defaultValue?: string | ICoinValue;
  className?: string;
  prefixElement?: React.ReactNode;
  onChange?: (value: any, option: { value: any; view: React.ReactNode }) => void;
  itemClick?: (value: any) => void;
  withSearch?: boolean;
  searchChange?: (value: string) => void;
  filterFunction?: (value: string) => void;
  prefixClickNotOpen?: boolean;
  selectDropDownClassName?: string;
  dropDownMaxHeight?: number;
  placeholder?: string;
  /* ToDo : it would be ideal to also support ref */
  wrapperSelector?: string;
  disableDropDown?: boolean;
}

const handleStrToCoin = (value: string | ICoinValue, options: ICoinValue[]): ICoinValue => {
  if (typeof value === 'string') {
    return options.find(option => option.value === value);
  }
  return value;
};
const CoinSelect: React.FC<ICoinSelect> = props => {
  const {
    options = [],
    defaultValue,
    value,
    className = '',
    onChange,
    itemClick,
    filterFunction,
    searchChange,
    withSearch = true,
    prefixElement,
    prefixClickNotOpen = false,
    dropDownMaxHeight = 308,
    placeholder = 'Select coin',
    wrapperSelector = "[data-popup='popup']",
    disableDropDown = false,
    ...rest
  } = props;
  const [openDropdown, setOpenDropdown] = useState(false);
  const selectElementRef = useRef<HTMLDivElement>(null);
  const [valueState, setValueState] = useState(handleStrToCoin(value || defaultValue, options));
  const [searchValue, setSearchValue] = useState('');
  const dropDownDistanceFromBottom = useRef<number>(null);
  const dropDownRef = useRef<HTMLDivElement>(null);
  const [selectElementDetail, setSelectElementDetail] = useState<{ x: number; y: number; width: number }>(null);

  useEffect(() => {
    if (value) {
      setValueState(handleStrToCoin(value, options));
    }
  }, [value, options]);

  const handleSearch = (searchQuery = '') => {
    if (searchChange) {
      searchChange(searchQuery);
    } else {
      setSearchValue(searchQuery);
    }
  };

  useOutsideAlerter([selectElementRef, dropDownRef], () => setOpenDropdown(false));

  const handleDomPositions = () => {
    if (isBrowser() && selectElementRef.current && openDropdown === false) {
      const dropDownFromBottom = window.innerHeight - selectElementRef.current.getBoundingClientRect().bottom - 160;
      const { x, y, width, height } = selectElementRef.current.getBoundingClientRect();
      setSelectElementDetail({ x: x + window.scrollX, y: y + window.scrollY + height, width });
      if (dropDownDistanceFromBottom.current !== dropDownFromBottom) {
        dropDownDistanceFromBottom.current === dropDownFromBottom;
        if (dropDownFromBottom < dropDownMaxHeight) {
          const dropDownItemHeight = 52;
          const roundHeightFraction =
            dropDownFromBottom % dropDownItemHeight > dropDownItemHeight / 2
              ? +dropDownFromBottom % dropDownItemHeight
              : -dropDownFromBottom % dropDownItemHeight;
          setDropDownCalcHeight(dropDownFromBottom + roundHeightFraction - 1);
        } else {
          setDropDownCalcHeight(dropDownMaxHeight);
        }
      }
    }
  };
  /* handle dropDown open overflow the size of the screen in the modal */
  /* ToDo it would be nice to find a way to do this without fixed numbers */
  const [dropDownCalcHeight, setDropDownCalcHeight] = useState(dropDownMaxHeight);
  useLayoutEffect(() => {
    /* modal appear with delay */
    /* this method try to adjust the dropdown size according to the user view for better UX */
    setTimeout(() => {
      handleDomPositions();
    }, 500);
  }, [openDropdown]);

  useLayoutEffect(() => {
    /* in case there is a scroll in the modal */
    document.querySelector(wrapperSelector)?.addEventListener(
      'scroll',
      () => {
        handleDomPositions();
      },
      { passive: true }
    );
    return () => document.querySelector(wrapperSelector)?.removeEventListener('scroll', () => handleDomPositions());
  }, []);

  useLayoutEffect(() => {
    const debouncedHandleDomPositions = debounce(handleDomPositions, 50);
    window.addEventListener('resize', () => debouncedHandleDomPositions());
    return () => window.removeEventListener('resize', () => debouncedHandleDomPositions());
  }, []);

  return (
    <div ref={selectElementRef} className={`${style['coin-select-wrapper']} ${className}`} {...rest}>
      <div
        className={style['coin-select-header']}
        onClick={() => !prefixClickNotOpen && setOpenDropdown(!openDropdown)}
      >
        {prefixElement}
        <div
          className={`${style['coin-select-dropdown-trigger']}`}
          onClick={() => prefixClickNotOpen && setOpenDropdown(!openDropdown)}
        >
          <div className={style['coin-select-value']}>{valueState ? valueState.view : placeholder}</div>
          <ArrowDown className={style['coin-select-icon']} />
        </div>
      </div>
      {openDropdown &&
        !(valueState && options.length === 1) &&
        !disableDropDown &&
        createPortal(
          <div
            ref={dropDownRef}
            className={style['coin-select-drop-down-wrapper']}
            style={{
              left: selectElementDetail.x,
              top: selectElementDetail.y,
              width: `${selectElementDetail.width || 500}px`,
            }}
          >
            {withSearch && (
              <div className={style['coin-select-search']}>
                <SearchIcon className={style['coin-select-search-icon']} />
                <input
                  className={style['coin-select-search-input']}
                  onChange={e => handleSearch(e.target.value)}
                  type='text'
                  placeholder='Search'
                />
              </div>
            )}
            <div
              className={style['coin-select-dropdown-item-wrapper']}
              style={{ maxHeight: `${dropDownCalcHeight}px` }}
            >
              {options
                .filter(option => filterFunction || option.value.toLowerCase().includes(searchValue.toLowerCase()))
                .map(option => (
                  <div
                    className={style['coin-select-dropdown-item']}
                    data-is-selected-already={option?.value === valueState?.value}
                    key={option.value}
                    tabIndex={0}
                    onClick={() => {
                      itemClick ? itemClick(option.value) : setOpenDropdown(false);
                      if (valueState && valueState.value === option.value) return;
                      onChange && onChange(option.value, option);
                      if (!value) {
                        /* if there isn't a value so the component is not controlled, we set the value state */
                        setValueState(option);
                      }
                    }}
                  >
                    {option.view}
                  </div>
                ))}
            </div>
          </div>,
          document.body
        )}
    </div>
  );
};

export default CoinSelect;
