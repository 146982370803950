import classNames from 'classnames';
import { useState } from 'react';

import style from './style.module.scss';

interface IProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  name: string;
  width?: number;
  className?: string;
  onError?: () => void;
}

const CoinIcon: React.FC<IProps> = props => {
  const { name, width, className = '', onError, ...rest } = props;
  const [imageError, setImageError] = useState(false);

  const handleError = () => {
    setImageError(true);
    onError && onError();
  };

  return (
    <>
      {!imageError && (
        <img
          src={`/img/icon/${name}.svg`}
          onError={handleError}
          className={classNames(style['coin-icon'], className)}
          alt={name}
          width={width}
          {...rest}
        />
      )}
      {imageError && <span className={classNames(style['text-substitute'], className)}>{name}</span>}
    </>
  );
};

export default CoinIcon;
