import { ApiErrorResponseToastContent } from '@ping/uikit';
import { EToastType } from '@ping/enums';
import classnames from 'classnames';
import { toast, ToastContainer } from 'react-toastify';

import style from './style.module.scss';

import type { ApiErrorResponse } from '@ping/api';
import type { IToastProps } from '@ping/uikit';
import type IReactToastify from 'react-toastify';

/**
 * @function Toast
 * @description Toast Component for notification
 * @param props
 * @constructor
 */
const Toast = ({ className, customClass, ...rest }: IToastProps) => (
  <ToastContainer className={`${className} ${customClass}`} {...rest} />
);

Toast.defaultProps = {
  position: 'top-center',
  rtl: true,
  hideProgressBar: true,
  customClass: '',
  autoClose: false,
  toastClassName: 'ping-toast',
  className: 'ping-toast__container',
};

interface IDisplayToast {
  type: EToastType;
  content: IReactToastify.ToastContent | string;
  options?: IReactToastify.ToastOptions;
}

/**
 * It takes an object with a type, content, and options
 * @param {IDisplayToast}  - type - the type of toast to display. This is used to set the className of the
 * toast.
 */
export const displayToast = ({ type, content, options }: IDisplayToast) => {
  options.type = type;
  options.className = classnames(style['ping-toast'], style[`ping-toast--${type}`]);

  toast(
    props => (
      <section className={style['ping-toast__content']}>
        {typeof content === 'function' ? content?.(props) : content}
      </section>
    ),
    options
  );
};

Toast.info = (content: IReactToastify.ToastContent | string, options: IReactToastify.ToastOptions = {}) =>
  displayToast({ type: EToastType.INFO, content, options });

Toast.success = (content: IReactToastify.ToastContent | string, options: IReactToastify.ToastOptions = {}) =>
  displayToast({ type: EToastType.SUCCESS, content, options });

Toast.warn = (content: IReactToastify.ToastContent | string, options: IReactToastify.ToastOptions = {}) =>
  displayToast({ type: EToastType.WARNING, content, options });

Toast.error = (
  content: IReactToastify.ToastContent | string | ApiErrorResponse,
  options: IReactToastify.ToastOptions = {}
) => {
  let toastContent = content;

  if (typeof content === 'object' && 'errorCode' in (content as ApiErrorResponse)) {
    const { errorCode, ...rest } = content as ApiErrorResponse;
    toastContent = <ApiErrorResponseToastContent {...rest} />;
    options.toastId ||= errorCode;
  }

  return displayToast({
    type: EToastType.ERROR,
    content: toastContent as IReactToastify.ToastContent | string,
    options,
  });
};

export default Toast;
