import { queryClient } from '@ping/providers/ReactQueryProvider';
import { tokenStore } from '@ping/stores/token.store';
import { userInformationStore } from '@ping/stores/userInformation.store';

/**
 * It clears the token and user information from the respective stores
 */
export const signoutReset = () => {
  tokenStore.revokeToken();
  userInformationStore.revokeUserInformation();
  queryClient.clear();
};
