import React, { ButtonHTMLAttributes, FunctionComponent } from 'react';
import classNames from 'classnames/bind';
import style from './style.module.scss';

const cx = classNames.bind(style);

interface IProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'size' | 'type'> {
  type?: 'primary' | 'secondary';
  size?: 'large' | 'small' | 'extra-large';
  design?: 'general' | 'buy' | 'sell' | 'gray';
  disabled?: boolean;
  htmlType?: 'submit' | 'reset' | 'button';
  uppercase?: boolean;
}

const Button: FunctionComponent<IProps> = ({
  type = 'primary',
  size = 'small',
  design = 'general',
  children,
  disabled = false,
  className = '',
  htmlType = 'button',
  uppercase = false,
  ...rest
}) => {
  const classname = cx('component_btn', {
    [`type_${type}`]: type,
    [`size_${size}`]: size,
    [`design_${design}`]: design,
    [`${className}`]: className,
    disabled,
    uppercase,
  });
  return (
    <button type={htmlType} className={classname} disabled={disabled} {...rest}>
      {children}
    </button>
  );
};

export default Button;
