import { ECurrencyValue } from '@ping/enums';

import type { ICoinValue } from '@ping/uikit';

export const DEFAULT_CURRENCIES: ICoinValue<ECurrencyValue>[] = [
  {
    value: ECurrencyValue.USD,
    view: <span>USD</span>,
  },
  {
    value: ECurrencyValue.EUR,
    view: <span>EUR</span>,
  },
];
