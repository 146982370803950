import { ApiErrorResponse } from '@ping/api';
import { Toast } from '@ping/uikit';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import type { ErrorType } from '@ping/api/custom-instance';
import type { DehydratedState } from 'react-query';

export interface IReactQueryProviderProps {
  dehydratedState?: DehydratedState;
  children: React.ReactNode;
}

const handleOnRequestError = (response: ApiErrorResponse) => {
  Boolean(response) && Toast.error(response);
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // we don't need to refetch on every focus. that's just unnecessary stress on server maybe add it later again
      refetchOnWindowFocus: false,
      onError: (error: ErrorType<ApiErrorResponse>) => handleOnRequestError(error?.response?.data),
    },
    mutations: {
      onError: (error: ErrorType<ApiErrorResponse>) => handleOnRequestError(error?.response?.data),
    },
  },
});

const ReactQueryProvider = ({ children, dehydratedState }: IReactQueryProviderProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={dehydratedState}>{children}</Hydrate>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default ReactQueryProvider;
