const SEARCH_VALUE_PATTERN = '%s' as const;

/**
 * It takes a dictionary of translations, a string to translate, and a list of parameters.
 * It translates the text while replaces all the %s with the params passed in respectively.
 * @param translations - Record<string, string> - this is the object that contains all the
 * translations.
 * @param {string} text - the string to translate
 * @param {string[]} params - Record<string, string> - this is the object that contains all the
 * translations.
 */
export const translate = (translations: Record<string, string>, text: string, ...params: string[]) => {
  //
  // convention to replace params inside text with %s, so we can replace them with real values
  const pattern = new RegExp(SEARCH_VALUE_PATTERN);
  const translation = (params || []).reduce(
    (translation, param) => translation.replace(pattern, param),
    translations?.[text] || text
  );

  return translation;
};
