import { DEFAULT_CURRENCIES } from '@ping/constants/default-currencies.constant';
import { ECurrencyValue } from '@ping/enums';
import { useIsUserLoggedIn, useSelectedCurrency, useUpdateCurrencySetting } from '@ping/hooks';
import { preferencesStore } from '@ping/stores/preferences.store';
import { Toast } from '@ping/uikit';
import { NavDropdown } from 'react-bootstrap';

import style from './style.module.scss';

const CurrencyDropDown: React.FC = () => {
  const selectedCurrency = useSelectedCurrency();
  const isUserLoggedIn = useIsUserLoggedIn();
  const { updateSettingAPI } = useUpdateCurrencySetting();

  const handleSelectCurrency = (currencyValue: ECurrencyValue) => {
    if (isUserLoggedIn) {
      updateSettingAPI(currencyValue, () => {
        Toast.success('The current currency has been changed successfully');
      });
      preferencesStore.setSelectedFiat(null);
    } else {
      preferencesStore.setSelectedFiat(currencyValue);
    }
  };

  return (
    <NavDropdown id={style['nav-dropdown-currency']} title={selectedCurrency.toUpperCase()}>
      {DEFAULT_CURRENCIES.map(currency => (
        <NavDropdown.Item
          key={currency.value}
          className={style['nav-currency-dropdown']}
          onClick={() => handleSelectCurrency(currency.value.toLowerCase() as ECurrencyValue)}
        >
          {currency.view}
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  );
};

export default CurrencyDropDown;
