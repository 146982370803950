import { isUserVerificationInProgressSelector, useUserInformationStore } from '@ping/stores/userInformation.store';
import { disablePageScroll } from '@ping/utils';
import { useEffect } from 'react';

import style from './style.module.scss';

const UserVerificationOverlay = () => {
  const isUserVerificationInProgress = useUserInformationStore(isUserVerificationInProgressSelector);
  useEffect(() => disablePageScroll(isUserVerificationInProgress, 'verification'), [isUserVerificationInProgress]);

  return (
    <>
      {isUserVerificationInProgress && (
        <div className={`[ ${style.user_verification_overlay} ] [ overlay ]`}>
          <p className={`[ ${style.user_verification_text} ]`}>Verification in progress ...</p>
        </div>
      )}
    </>
  );
};

export default UserVerificationOverlay;
