import UserKYCRequiredBanner from './UserKYCRequiredBanner';
import TerminatedUserBanner from './TerminatedUserBanner';

const BannersContainer = () => (
  <>
    <UserKYCRequiredBanner />
    <TerminatedUserBanner />
  </>
);

export default BannersContainer;
