import { LANGUAGE_LIST } from '@ping/constants/languages.constant';
import { t } from '@ping/helpers';
import { preferencesStore } from '@ping/stores/preferences.store';
import { Popup } from '@ping/uikit';

import style from './style.module.scss';

import type { IPopupProps } from '@ping/uikit';

interface ILanguageDialogProps extends Omit<IPopupProps, 'children'> {
  className?: string;
  onClose(): void;
}

const LanguageDialog = (props: ILanguageDialogProps) => {
  const { className = '', onClose, ...rest } = props;

  const handleLanguageChange = (lang: Omit<ILanguageState, 'texts'>) => {
    preferencesStore.setSelectedLanguage(lang);
    if (onClose) {
      onClose();
    }
  };

  return (
    <Popup onClose={onClose} {...rest}>
      <div className={style['language-dialog__wrapper']}>
        <h5>{t('Choose a language')}</h5>
        <div className={style['language-dialog__content']}>
          {LANGUAGE_LIST.map(language => (
            <span
              role='button'
              onClick={() => handleLanguageChange({ label: language.label, value: language.value })}
              className={style['language-dialog__item']}
              key={language.value}
            >
              {language.label}
            </span>
          ))}
        </div>
      </div>
    </Popup>
  );
};

export default LanguageDialog;
