import React, { FunctionComponent } from 'react';
import classNames from 'classnames/bind';
import style from './style.module.scss';

const cx = classNames.bind(style);

interface IProps {
  heading?: string;
  body?: 'medium' | 'regular' | 'semi-bold';
  caption?: 'regular' | 'semi-bold';
  text?: 'electric-blue' | 'cadet-blue';
  className?: string;
  uppercase?: boolean;
  children: React.ReactNode;
  isError?: boolean;
  blueColor?: boolean;
  grayColor?: boolean;
  badge?: boolean;
}

const Text: FunctionComponent<IProps> = ({
  heading,
  caption,
  badge,
  body,
  text,
  className = '',
  children,
  uppercase = false,
  isError = false,
  blueColor = false,
  grayColor = false,
  ...rest
}) => {
  const classname = cx('text', {
    [`body_${body}`]: body,
    [`caption_${caption}`]: caption,
    [`heading_${heading}`]: heading,
    [`text_${text}`]: text,
    [`${className}`]: className,
    isError,
    uppercase,
    blueColor,
    grayColor,
    badge,
  });
  return (
    <span className={classname} {...rest}>
      {children}
    </span>
  );
};

export default Text;
