import React from 'react';
import { CopyToClipboard, Props as CopyToClipboardProps } from 'react-copy-to-clipboard';
import CopyIcon from './copy.svg';
import style from './style.module.scss';

interface IProps extends CopyToClipboardProps {
  hasIcon?: boolean;
  className?: string;
}

const Copy: React.FC<IProps> = props => {
  const { text, onCopy, children = null, hasIcon, className } = props;
  return (
    <CopyToClipboard text={text} onCopy={onCopy}>
      <span className={className}>
        {(!children || hasIcon) && <CopyIcon className={style.pingCopyIcon} width={24} height={24} />}
        {children}
      </span>
    </CopyToClipboard>
  );
};

export default Copy;
