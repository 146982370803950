import { TERMINATED_USER_ERROR_CODE } from '@ping/authorization/side-effects';
import { signoutReset } from '@ping/helpers';
import { tokenStore } from '@ping/stores/token.store';
import { isBrowser } from '@ping/utils';

import { axiosInstance } from './axios-instance';

const UNAUTHORIZED = 401 as const;

let authorizer = null;
authorizer = isBrowser() && import('@ping/authorization/authorizer').then(module => (authorizer = module.authorizer));

let previousRequestInterceptor: number = null;
let previousResponseInterceptor: number = null;

export const setInterceptors = () => {
  // Set a request interceptor to send Bearer token if user is signed-in
  if (previousRequestInterceptor) {
    axiosInstance.interceptors.request.eject(previousRequestInterceptor);
  }
  previousRequestInterceptor = axiosInstance.interceptors.request.use(config => {
    return config;
  });

  // Set a response interceptor in case of 401 errors that might be related to token expiration
  // Try to refresh the token and retry the request. if it keeps failing on retry attempt, give up and
  // throw the error back to the requester
  if (previousResponseInterceptor) {
    axiosInstance.interceptors.response.eject(previousResponseInterceptor);
  }
  previousResponseInterceptor = axiosInstance.interceptors.response.use(
    response =>
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      response,
    handleOnRejected
  );
};

const handleOnRejected = async (error: any) => {
  let reason = error;
  const originalRequest = error.config;
  const errorCode = error?.response?.data?.errorCode;

  if (error?.response?.status === UNAUTHORIZED && errorCode !== TERMINATED_USER_ERROR_CODE && !originalRequest._retry) {
    originalRequest._retry = true;
    const { refreshToken } = tokenStore.getTokenResponse();

    if (Boolean(refreshToken) && 'silentSignin' in authorizer) {
      try {
        await authorizer.silentSignin();
        return Promise.resolve(axiosInstance(originalRequest));
      } catch (error) {
        reason = error;
      }
    }
  }

  if (error?.response?.status === UNAUTHORIZED) {
    signoutReset();
  }

  return Promise.reject(reason);
};
