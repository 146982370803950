import { useEffect } from 'react';

/**
 * Hook to handle click outside events
 * @param ref Ref to the element to handle click outside events
 * @param callback callback to call when the user clicks outside the element
 * @link https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
 */

export default function useOutsideAlerter(ref, callback) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (Array.isArray(ref)) {
        if (!ref.some(elem => elem.current?.contains(event.target))) {
          callback();
        }
      } else if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}
