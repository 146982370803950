import { getOrCreateProfile } from '@ping/api';
import { axiosInstance } from '@ping/api/axios-instance';
import { TOAST_OPTIONS } from '@ping/constants/toast-options.constant';
import { tokenStore } from '@ping/stores/token.store';
import { userInformationStore } from '@ping/stores/userInformation.store';
import { Toast } from '@ping/uikit';
import { isBrowser } from '@ping/utils';
import router from 'next/router';

import { LOGIN_CALLBACK_PATH, LOGIN_PATH, LOGOUT_REDIRECTION_CALLBACK_PATH } from './constants';

import type { ApiErrorResponse } from '@ping/api';

let authorizer = null;
isBrowser() && import('./authorizer').then(module => (authorizer = module.authorizer));

export const REFERRAL_CODE_STORAGE = 'refCode';
export const TERMINATED_USER_ERROR_CODE = 30200;
const NOT_KYCED_ERROR_CODE = 50002;

export const loginSideEffect = () => {
  const { pathname, search } = window.location;
  const redirectLoginTo = pathname + search;
  if (!redirectLoginTo.startsWith('/login')) {
    localStorage.setItem('loginRedirectTo', redirectLoginTo);
    if (search.includes(REFERRAL_CODE_STORAGE)) {
      localStorage.setItem(REFERRAL_CODE_STORAGE, new URLSearchParams(search).get(REFERRAL_CODE_STORAGE));
    }
  }
  authorizer.makeAuthorizationRequest();
};

export const logInCallbackSideEffect = async () => {
  if (authorizer) {
    await authorizer.signinCallback();
    const redirectLoginTo = localStorage.getItem('loginRedirectTo') || '/';
    localStorage.removeItem('loginRedirectTo');

    const accessToken = tokenStore.getAccessToken();
    if (accessToken) {
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      console.log('Authorization header set', accessToken);
      let redirectTimeOut = 1_000;

      try {
        /* this call is needed before doing anything else on Ping project for logged-in users */
        await handleProfile();
        // await loadUserSideEffect();
      } catch (error: any) {
        if (error?.response?.data) {
          Toast.error(error?.response?.data as ApiErrorResponse);
          redirectTimeOut = TOAST_OPTIONS.autoClose || 5_000;
        }
      }

      setTimeout(() => router.push(redirectLoginTo), redirectTimeOut);
    }
  } else {
    setTimeout(logInCallbackSideEffect, 400);
  }
};

export const handleProfile = async () => {
  try {
    const profile = await getOrCreateProfile({
      referralCode: localStorage.getItem(REFERRAL_CODE_STORAGE),
    });

    if (profile.verificationLevel >= 1 && !profile.verificationIsInProgress) {
      localStorage.removeItem(REFERRAL_CODE_STORAGE);
    }

    userInformationStore.setUserInformation(profile);
  } catch ({ response }) {
    if (response?.data?.errorCode === NOT_KYCED_ERROR_CODE) {
      userInformationStore.setUserInformation({
        verificationError: response?.data?.errors[0].message,
      });
    } else if (response?.data?.errorCode === TERMINATED_USER_ERROR_CODE) {
      userInformationStore.setUserInformation({
        isTerminated: true,
      });
    } else {
      throw { response };
    }
  }
};

export const logOutSideEffect = async () => {
  localStorage.removeItem('user');
  await authorizer.signout();
};

export const logOutCallbackSideEffect = async () => {
  await authorizer.signoutCallback();
};

export const useHandleLocationChangedSideEffect = async () => {
  const { pathname } = window.location;

  const handleAsyncAwait = async () => {
    if (pathname === LOGIN_CALLBACK_PATH) {
      await logInCallbackSideEffect();
    }

    if (pathname === LOGIN_PATH) {
      // await throttledLogin(dispatch)
    }

    if (pathname === LOGOUT_REDIRECTION_CALLBACK_PATH) {
      // await throttledLogoutCallback(dispatch)
    }
  };
  await handleAsyncAwait();
};
