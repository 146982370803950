import NextQueryParamProvider from './NextQueryParamProvider';
import ReactAriaOverlayProvider from './ReactAriaOverlayProvider';
import ReactQueryProvider from './ReactQueryProvider';

import type { IReactQueryProviderProps } from './ReactQueryProvider';

export interface IProvidersProps extends IReactQueryProviderProps {
  children: React.ReactNode;
}

const Providers = ({ children, dehydratedState }: IProvidersProps) => (
  <ReactQueryProvider dehydratedState={dehydratedState}>
    <NextQueryParamProvider>
      <ReactAriaOverlayProvider>{children}</ReactAriaOverlayProvider>
    </NextQueryParamProvider>
  </ReactQueryProvider>
);

export default Providers;
