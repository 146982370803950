import { useDepositWallet, useGetAllAsset, useGetPortfolio } from '@ping/api';
import { useSelectedCurrency } from '@ping/hooks';
import { CoinItem, CoinSelect, Copy, Text, Toast } from '@ping/uikit';
import { format } from '@ping/utils';
import { useEffect, useRef } from 'react';
import QRCode from 'react-qr-code';

import style from './style.module.scss';

import type { PortfolioItemResponse } from '@ping/api';
import type { ICoinValue } from '@ping/uikit';

type IDepositProps = {
  selectedAsset?: string;
};

interface IAsset {
  address: string;
  id: string;
  amount: number;
  balance: number;
  confirmationCount: number | undefined;
}

// TODO: check api call orders first then make it global
const FIVE_MINUTES = 5 * 60 * 1000;
const ONE_DAY = 1 * 60 * 60 * 24 * 1000;

const INITIAL_ASSET: IAsset = {
  address: '',
  id: '',
  amount: 0,
  balance: 0,
  confirmationCount: undefined,
};

const Deposit = (props: IDepositProps) => {
  const selectedCurrency = useSelectedCurrency();
  const assetRef = useRef<IAsset>({ ...INITIAL_ASSET, id: props.selectedAsset?.toUpperCase() });

  const { mutate: mutateRequestFundsDepositWallet, isLoading: isLoadingRequestFundsDepositWallet } = useDepositWallet();

  const { data: portfolio, isLoading: isPortfolioLoading } = useGetPortfolio({
    query: {
      staleTime: FIVE_MINUTES,
      select: ({ portfolioItems }) => {
        const assetsMap = portfolioItems?.reduce(
          (map, item) => map.set(item.assetId.toUpperCase(), item),
          new Map<string, PortfolioItemResponse>()
        );

        return { assetsMap };
      },
    },
  });

  const { data: assets, isLoading: isAssetsLoading } = useGetAllAsset({
    query: {
      staleTime: ONE_DAY,
      select: ({ data: allAssets }) => {
        const options = allAssets.reduce<ICoinValue<string>[]>((options, asset) => {
          if (asset.can_deposit) {
            options.push({
              value: asset.id.toUpperCase(),
              view: <CoinItem className='cursor-pointer' coinName={asset.id.toUpperCase()} />,
            });
          }

          return options;
        }, []);

        const confirmationCountsMap = allAssets?.reduce(
          (map, item) => map.set(item.id.toUpperCase(), item.confirmation_count),
          new Map<string, number | undefined>()
        );

        return { options, confirmationCountsMap };
      },
    },
  });

  useEffect(() => handleOnSelectAsset(assetRef.current.id), [isPortfolioLoading, isAssetsLoading]);

  const handleOnSelectAsset = (assetId: string) => {
    if (isPortfolioLoading || isAssetsLoading || !assetId?.length) return;

    mutateRequestFundsDepositWallet(
      { data: { assetId: assetId.toLowerCase() } },
      {
        onSuccess: deposit => {
          const { amount, balance } = portfolio.assetsMap.get(assetId) || {};
          const confirmationCount = assets.confirmationCountsMap.get(assetId);

          assetRef.current = {
            address: deposit.address,
            id: assetId.toUpperCase(),
            amount: amount || INITIAL_ASSET.amount,
            balance: balance || INITIAL_ASSET.balance,
            confirmationCount: confirmationCount || INITIAL_ASSET.confirmationCount,
          };
        },
        onError: () => {
          assetRef.current = INITIAL_ASSET;
        },
      }
    );
  };

  const handleOnCopyAssetAddress = (address: string) => {
    Toast.success('Wallet address copied.', { toastId: address });
  };

  return (
    <div
      className={style['deposit']}
      data-deposit-address-loading={isLoadingRequestFundsDepositWallet}
      data-is-asset-selected={Boolean(assetRef.current.address.length)}
    >
      <div className={style['deposit__asset']}>
        <div>
          <Text body='regular' className={style['deposit__coin-select-label']}>
            Currency
          </Text>

          <CoinSelect options={assets?.options} value={assetRef.current.id} onChange={handleOnSelectAsset} withSearch />
        </div>
        <Text caption='regular' className={style['deposit__asset-balance']}>
          {`${assetRef.current.id} Balance - ${format.crypto(assetRef.current.amount, assetRef.current.id)}`}
          {Boolean(assetRef.current.amount) && ` (${format.fiat(assetRef.current.balance, selectedCurrency)})`}
        </Text>
      </div>

      <section className={style['deposit__address-section']}>
        <div className={style['deposit__qr-code-wrapper']}>
          <QRCode className={style['deposit__qr-code']} size={136} value={assetRef.current.address} />
        </div>

        <div className={style['deposit__address-details']}>
          <Text className={style['deposit__address-title']} body='semi-bold'>
            {`${assetRef.current.id} address`}
          </Text>

          <Copy
            className={style['deposit__address-copy']}
            text={assetRef.current.address}
            onCopy={handleOnCopyAssetAddress}
          />

          <Text className={style['deposit__address-text']} body='regular'>
            {assetRef.current.address}
          </Text>
        </div>
      </section>

      <section className={style['deposit__info-section']}>
        <Text className={style['deposit__info-text']} caption='regular'>
          Expected arrival
        </Text>
        {/* TODO: Minimum deposit */}
        <Text className={style['deposit__info-text']} caption='regular'>
          {`${assetRef.current.confirmationCount} network confirmation`}
        </Text>
      </section>

      <section className={style['deposit__footer-section']}>
        <Text className={style['deposit__footer-text']} caption='regular'>
          Please remember to send only {assetRef.current.id} to this deposit address
        </Text>
      </section>
    </div>
  );
};

export default Deposit;
