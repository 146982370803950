import { Store } from '@ping/utils';

import type { ProfileResponse } from '@ping/api';

export type IUserInformationStore = ProfileResponse & {
  isTerminated?: boolean;
};

// --------------------------------------------------------------------------------
// USER INFORMATION STORE
// --------------------------------------------------------------------------------
export const useUserInformationStore = new Store<IUserInformationStore>({} as IUserInformationStore)
  .withPersist({ name: 'USER_INFORMATION_STORE' })
  .withDevTools()
  .build();

// --------------------------------------------------------------------------------
// USER INFORMATION STORE SELECTORS
// --------------------------------------------------------------------------------
export const isUserVerificationInProgressSelector = (state: IUserInformationStore) =>
  Boolean(state?.verificationIsInProgress);

/*
 * For clarification, previously, we used isUserLoggedInSelector in the token store to check whether
 * the user is logged in or not but there was an issue when the user didn't complete the KYC process.
 * Moreover, in the back-end, they do not create a profile for that user but return accessToken to us,
 * and since we only checked accessToken to detect if the user is logged in, we had some issues.
 * This is why I added this selector which will help us to recognize we have profile data in our state and
 * ensure the user is fully logged in.
 */
export const isUserDataExistSelector = (state: IUserInformationStore) => 'roles' in state;

export const isKYCedRequiredSelector = (state: IUserInformationStore) => !!state?.verificationError;

export const isUserAccountTerminatedSelector = (state: IUserInformationStore) => !!state?.isTerminated;

export const isUserVerifiedSelector = (state: IUserInformationStore) => state?.verificationLevel !== 0;

export const isUserAdminSelector = (state: IUserInformationStore) => Boolean(state?.roles?.includes('admin'));

// --------------------------------------------------------------------------------
// USER INFORMATION STORE API
// --------------------------------------------------------------------------------
export const userInformationStore = {
  setUserInformation: (userInformation: IUserInformationStore, replace = true) =>
    useUserInformationStore.setState(userInformation, replace),
  revokeUserInformation: () => useUserInformationStore.setState({}, true),
};
