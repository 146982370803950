import { useState } from 'react';
import SVG from 'react-inlinesvg';
import { DoubleConfirmModal, Text } from '@ping/uikit';
import type { IDoubleConfirmModalProps } from '@ping/uikit';
import style from './style.module.scss';

interface IProps {
  show: boolean;
  message: string;
  modal?: Omit<IDoubleConfirmModalProps, 'show' | 'onClose'>;
}

const Banner = ({ show, message, modal }: IProps) => {
  const [showModal, setShowModal] = useState(false);

  const handleBannerClick = () => {
    if (modal) {
      setShowModal(true);
    }
  };

  return (
    <>
      {show && (
        <div className={style['banner']} onClick={handleBannerClick}>
          <SVG height={18} width={18} src='/img/info.svg' />
          <Text caption='regular'>{message}</Text>
        </div>
      )}
      {Boolean(modal) && (
        <DoubleConfirmModal
          title={modal?.title}
          show={showModal}
          onClose={() => setShowModal(false)}
          cancelText={modal?.cancelText}
          confirmText={modal?.confirmText}
          onConfirm={modal?.onConfirm}
        >
          {modal?.children}
        </DoubleConfirmModal>
      )}
    </>
  );
};

export default Banner;
