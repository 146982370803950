import '@ping/assets/css/style.css';
import '@ping/assets/scss/style.scss';

import { setInterceptors } from '@ping/api/interceptors';
import { useHandleLocationChangedSideEffect } from '@ping/authorization/side-effects';
import { BannersContainer, Layout, UserVerificationOverlay } from '@ping/components';
import { TOAST_OPTIONS } from '@ping/constants/toast-options.constant';
import { useForceUpdate } from '@ping/hooks';
import Providers from '@ping/providers';
import { checkLanguageChange } from '@ping/stores/preferences.effects';
import { preferencesStore } from '@ping/stores/preferences.store';
import { checkUserVerification } from '@ping/stores/userInformation.effects';
import { Toast } from '@ping/uikit';
import dynamic from 'next/dynamic';
import { useEffect } from 'react';

import type { AppProps } from 'next/app';

const PingExchangeApp = ({ Component, pageProps }: AppProps) => {
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    useHandleLocationChangedSideEffect().then();
    setInterceptors();
    // TODO: Change it back to get user setting and everything when we want to support light theme
    // applyUserSystemPreferenceTheme()
    preferencesStore.setDefaultTheme();
    const userVerificationSubscription = checkUserVerification();
    const languageChangeSubscription = checkLanguageChange(forceUpdate);

    return () => {
      userVerificationSubscription.unsubscribe();
      languageChangeSubscription.unsubscribe();
    };
  }, []);

  return (
    <Providers {...pageProps}>
      <Toast {...TOAST_OPTIONS} />
      <BannersContainer />
      <Layout>
        <Component {...pageProps} />
      </Layout>
      <UserVerificationOverlay />
    </Providers>
  );
};

/* render dev also in CSR */
export default dynamic(() => Promise.resolve(PingExchangeApp), {
  ssr: false,
});
